import { AppBar, Divider, Drawer, List, ListItem } from "@mui/material";

import React, { useState } from "react";
import {
  Logout,
  ManageSearch,
  ContactPage,
  Favorite,
  Menu,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { fetchLogOut } from "../UserSignInUpFetch";
import blacklogo from "../../assets/blacklogo.png";

export const ProfileBase = () => {
  const [isClosed, setIsClosed] = useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setIsClosed(!isClosed);
  };
  const handleLogOut = () => {
    fetchLogOut(navigate);
    navigate("/");
  };
  const handleNavigate = (location) => {
    navigate(location);
    handleDrawerToggle();
  };
  const handleNavigateToHomeDeleteSession = () => {
    navigate("/");
    sessionStorage.clear();
    window.location.reload();
  };
  const drawer = (
    <div className="text-white ">
      <List>
        <ListItem sx={{ cursor: "pointer", justifyContent: "center" }}>
          <div
            className=" flex gap-2 py-2"
            onClick={() => handleNavigate("/member/favorite")}
          >
            <div>
              <Favorite />
            </div>
            <p>我的最愛</p>
          </div>
        </ListItem>
        <Divider />
        <ListItem sx={{ cursor: "pointer", justifyContent: "center" }}>
          <div
            className="flex gap-2 py-2"
            onClick={() => handleNavigate("/member/profile")}
          >
            <div>
              <ContactPage />
            </div>
            <p>個人資料</p>
          </div>
        </ListItem>
        <Divider />
        <ListItem sx={{ cursor: "pointer", justifyContent: "center" }}>
          <div
            className="flex gap-2 py-2"
            onClick={() => handleNavigate("/member/history")}
          >
            <div>
              <ManageSearch />
            </div>
            <p>我的訂單</p>
          </div>
        </ListItem>
        <Divider />
        <ListItem sx={{ cursor: "pointer", justifyContent: "center" }}>
          <div className="flex gap-2 py-2" onClick={handleLogOut}>
            <div>
              <Logout />
            </div>
            <p>登出</p>
          </div>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="">
      <AppBar
        sx={{
          position: "fixed",
          bgcolor: "#344e41",
        }}
      >
        <div className="flex h-[64px]">
          <div
            color="inherit"
            edge="end"
            onClick={handleDrawerToggle}
            className="lg:hidden m-2 p-3 cursor-pointer"
          >
            <Menu />
          </div>
          <div
            className="cursor-pointer block w-20 my-1 ml-20"
            onClick={handleNavigateToHomeDeleteSession}
          >
            <img src={blacklogo} alt="" />
          </div>
        </div>
      </AppBar>

      <div>
        <Drawer
          variant="temporary"
          open={isClosed}
          disableScrollLock
          onClose={handleDrawerToggle}
          className="block lg:hidden"
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "300px",
              zIndex: "0",
              bgcolor: "#344e41",
              paddingTop: "70px",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className="lg:block hidden"
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "250px",
              zIndex: "0",
              bgcolor: "#344e41",
              paddingTop: "70px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </div>
    </div>
  );
};
