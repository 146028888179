import { Box, Button, Container, Grid, Typography } from "@mui/material";
import firstPic from "../assets/firstPic.png";
import { useNavigate } from "react-router-dom";

export default function Customer({ setReadAbout }) {
  const navigate = useNavigate();


  const handleClick = () => {
    const read = localStorage.getItem("read");
    if (!read) {
      localStorage.setItem("read", "yes");
      setReadAbout("yes");
      navigate("/");
    } else {
      navigate("/");
    }
  };
  return (
    <Container maxWidth="xl" id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "flex", alignItems: "center", mb: 3, px: 4 }}
        >
          <Box>
            <Typography
              variant="h3"
              color="text.primary"
              sx={{ mb: 1, fontWeight: 800, fontSize: { xs: 40, md: 48 } }}
            >
              SevenTao
            </Typography>
            <Typography
              variant="h3"
              color="text.primary"
              sx={{ fontSize: { xs: 32, md: 48 } }}
            >
              讓每次旅行
              <br />
              都充滿驚喜！
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ mt: 4, fontSize: { xs: 17, md: 20 } }}
            >
              無論是短暫的週末旅行，
              <br />
              還是充滿冒險的長途旅行，
              <br />
              我們都能為您找到最合適的住宿！
            </Typography>
            <Button
              onClick={handleClick}
              variant="contained"
              sx={{
                mt: 3,
                backgroundColor: "#343a40",
                ":hover": {
                  backgroundColor: "#343a40aa",
                },
                height: 50,
                width: 110,
                fontSize: 18,
              }}
            >
              立即體驗
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            component="img"
            sx={{
              width: "100%",
              pointerEvents: "none",
            }}
            src={firstPic}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
