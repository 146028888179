import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  styled,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import {
  Pool,
  EvStation,
  FitnessCenter,
  SmokeFree,
  DirectionsCar,
  SupportAgent,
  Restaurant,
  FmdGood,
  CloudUpload,
  Delete,
} from "@mui/icons-material";
import { AddressArray } from "../config/AddressData";
import { useFormik } from "formik";
import { uploadImageToCloudinary } from "../config/UploadToCloudinary";
import { CreateRoomForm } from "./CreateRoomForm";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchHotel,
  fetchCreateHotel,
  fetchUpdateHotel,
  fetchBossRoom,
  fetchCreateRoom,
  fetchDeleteRoom,
} from "./HotelPageFetch";
import { RoomCards } from "../cards/RoomCards";
import { getAuthToken } from "../config/config";
import { RoomDataCard } from "./RoomDataCard";
import { geocoderAddress } from "../cards/Map";

export const BossHotelCreate = ({ isEstablishing }) => {
  const { hotelId } = useParams();
  const [hotel, setHotel] = useState();
  const [roomList, setRoomList] = useState();
  const jwt = getAuthToken();

  useEffect(() => {
    if (!isEstablishing && hotelId) {
      fetchHotel(jwt, hotelId).then((hotel) => {
        setHotel(hotel);
      });
      fetchBossRoom(jwt, hotelId).then((rooms) => {
        setRoomList(rooms);
      });
    }
  }, [hotelId, isEstablishing, jwt]);

  const navigate = useNavigate();
  const fullHotelData = hotel;
  const emptyHotelData = {
    chName: "",
    enName: "",
    address: {
      city: 0,
      district: 1,
      street: "健康路",
      number: "1號",
    },
    introduction: "",
    pictures: {
      firstPic: "",
      secondPic: "",
      thirdPic: "",
    },
    facilities: [
      "游泳池",
      "充電樁",
      "健身房",
      "禁菸",
      "停車場",
      "全時櫃檯",
      "早餐",
    ],
    rooms: [],
    location: [],
  };
  const hotelData = isEstablishing ? emptyHotelData : fullHotelData;
  const placeholderImage =
    "https://crlab.io/wp-content/uploads/2018/11/1541399499-d22fbd0725562fba42c6d73edab0dd23.png";
  const formik = useFormik({
    initialValues: {
      hotel: {
        chName: hotelData?.chName || "",
        enName: hotelData?.enName || "",
        address: {
          city: hotelData?.address.city || 0,
          district: hotelData?.address.district || 1,
          street: hotelData?.address.street || "",
          number: hotelData?.address.number || "",
        },
        introduction: hotelData?.introduction || "",

        pictures: {
          firstPic: hotelData?.pictures[0] || "",
          secondPic: hotelData?.pictures[1] || "",
          thirdPic: hotelData?.pictures[2] || "",
        },

        facilities: hotelData?.facilities || [],
        position: hotelData?.position,
      },
      room: roomList || [],
    },
    enableReinitialize: true,

    onSubmit: (values) => {
      console.log("表單數據:", values);

      if (!isEstablishing) {
        // 獲取表單中的房間列表（當前保留的房間）
        const currentRooms = Array.isArray(values.room) ? values.room : [];

        // 區分新舊房間
        const newRoom = [];
        const oldRoom = [];

        currentRooms.forEach((room) => {
          if (room.roomId == null) {
            newRoom.push(room);
          } else {
            oldRoom.push(room);
          }
        });

        // 找出要刪除的房間：原始房間列表中有，但在當前表單中沒有的房間
        let deleteRoom = [];
        if (roomList && roomList.length !== 0) {
          deleteRoom = roomList.filter((originRoom) => {
            // 如果 currentRooms 是空的，表示要刪除所有房間
            if (!currentRooms || currentRooms.length === 0) {
              return true; // 刪除所有房間
            }
            // 否則檢查哪些房間不在當前列表中
            return !currentRooms.some(
              (room) => room.roomId === originRoom.roomId
            );
          });
        }

        console.log("要刪除的房間：", deleteRoom);

        const deleteRoomIds = deleteRoom.map((room) => room.roomId);

        console.log("處理結果:", {
          currentRooms,
          newRoom,
          oldRoom,
          deleteRoom,
          deleteRoomIds,
        });

        // 處理新增房間
        if (newRoom.length > 0) {
          try {
            console.log("create room : "+newRoom);
            
            fetchCreateRoom(newRoom, jwt, hotelId);
          } catch (e) {
            console.error("新增房間失敗:", e);
          }
        }

        // 處理刪除房間
        if (deleteRoomIds.length > 0) {
          try {
            console.log("準備刪除這些房間:", deleteRoomIds);
            fetchDeleteRoom(jwt, deleteRoomIds);
          } catch (e) {
            console.error("刪除房間失敗:", e);
          }
        }

        // 處理刪除房間
        if (deleteRoomIds && deleteRoomIds.length !== 0) {
          try {
            fetchDeleteRoom(jwt, deleteRoomIds);
          } catch (e) {
            console.error("刪除房間失敗:", e);
          }
        }
      }

      // getLocation(
      //   AddressArray[values.hotel.address.city][0] +
      //     AddressArray[values.hotel.address.city][
      //       values.hotel.address.district
      //     ] +
      //     values.hotel.address.street +
      //     values.hotel.address.number
      // ).then((location) => {
      //   if (location)
      //     console.log(
      //       AddressArray[values.hotel.address.city][0] +
      //         AddressArray[values.hotel.address.city][
      //           values.hotel.address.district
      //         ] +
      //         values.hotel.address.street +
      //         values.hotel.address.number
      //     );

      //   console.log(location);
      //   // formik.setFieldValue("hotel.position", [location.lat,location.lng]);
      // });
      const fullAddress =
        AddressArray[values.hotel.address.city][0] +
        AddressArray[values.hotel.address.city][values.hotel.address.district] +
        values.hotel.address.street +
        values.hotel.address.number;

      geocoderAddress(fullAddress).then((loc) => {
        console.log(loc);
        const hotelData = { ...values.hotel, location: loc };
        isEstablishing
          ? fetchCreateHotel(hotelData, jwt)
              .then((data) => {
                if (data) {
                  navigate("/boss/hotels");
                  // window.location.reload();
                }
              })
              .catch((e) => {
                console.log(e);
              })
          : fetchUpdateHotel(hotelData, hotelId, jwt)
              .then((data) => {
                if (data) {
                  navigate(`/boss/hotel/${hotelId}`);
                  // window.location.reload();
                }
              })
              .catch((e) => {
                console.log(e);
              });
      });
    },
  });
  const handleAddRoom = (newRoom) => {
    formik.setFieldValue("room", [...formik.values.room, newRoom]);
  };

  const handleDeleteRoom = (index) => {
    console.log(index);

    const newRooms = formik.values.room.filter((_, i) => i !== index);
    formik.setFieldValue("room", newRooms);
  };
  const [uploadImage, setUploadImage] = useState({
    "pictures.firstPic.url": false,
    "pictures.secondPic.url": false,
    "pictures.thirdPic.url": false,
  });
  const [openCreateRoom, setOpenCreateRoom] = useState(false);
  const [openEditRoom, setOpenEditRoom] = useState(false);
  const [selectRoom, setSelectRoom] = useState();
  const [selectIndex, setSelectIndex] = useState();
  const handleCreateOpen = () => setOpenCreateRoom(true);
  const handleCreateClose = () => setOpenCreateRoom(false);
  const handleEditOpen = (room, index) => {
    setOpenEditRoom(true);
    setSelectRoom(room);
    setSelectIndex(index);
  };
  const handleEditClose = () => setOpenEditRoom(false);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [facilitySign, setFacilitySign] = useState([
    { name: "游泳池", icon: <Pool />, exist: true },
    { name: "充電樁", icon: <EvStation />, exist: true },
    { name: "健身房", icon: <FitnessCenter />, exist: true },
    { name: "禁菸", icon: <SmokeFree />, exist: true },
    { name: "停車場", icon: <DirectionsCar />, exist: true },
    { name: "全時櫃檯", icon: <SupportAgent />, exist: true },
    { name: "早餐", icon: <Restaurant />, exist: true },
  ]);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleImageChange = async (file, fieldName) => {
    try {
      setUploadImage((prev) => ({
        ...prev,
        [fieldName]: true,
      }));
      const imageUrl = await uploadImageToCloudinary(file);
      formik.setFieldValue(fieldName, imageUrl);
    } catch (error) {
      console.log("uploading failure");
    } finally {
      setUploadImage((prev) => ({
        ...prev,
        [fieldName]: false,
      }));
    }
  };

  const handleImageRemove = (fieldName) => {
    setUploadImage((prev) => ({
      ...prev,
      [fieldName]: false,
    }));
    formik.setFieldValue(fieldName, null);
  };

  const handleFacilitySupplyDisplay = (name) => {
    const currentFacilities = formik.values.hotel.facilities || [];

    if (currentFacilities.includes(name)) {
      formik.setFieldValue(
        "hotel.facilities",
        currentFacilities.filter((facility) => facility !== name)
      );
    } else {
      formik.setFieldValue("hotel.facilities", [...currentFacilities, name]);
    }
  };

  if (!isEstablishing && !hotel) {
    return (
      <div className="flex items-center justify-center h-screen bg-black">
        <img
          className="max-w-full max-h-full object-contain"
          src="https://i.redd.it/ubbi1p7z7euc1.gif"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="container mx-auto mb-20">
        {isEstablishing ? (
          <div className="fixed flex right-[10vw] bottom-[100px] z-50">
            <div className="w-[200px] mx-3">
              <Button
                fullWidth
                size="large"
                sx={{ p: "10px", bgcolor: "#00000099", fontSize: "1.5rem" }}
                variant="contained"
                onClick={() => navigate("/boss/hotels")}
              >
                取消建立
              </Button>
            </div>
            <div className="w-[200px] mx-3">
              <Button
                fullWidth
                size="large"
                sx={{ p: "10px", bgcolor: "#00000099", fontSize: "1.5rem" }}
                variant="contained"
                type="submit"
              >
                建立旅店
              </Button>
            </div>
          </div>
        ) : (
          <div className="fixed flex right-[10vw] bottom-[100px] z-50">
            <div className="w-[200px] mx-3">
              <Button
                fullWidth
                size="large"
                sx={{ p: "10px", bgcolor: "#00000099", fontSize: "1.5rem" }}
                variant="contained"
                onClick={() => navigate(`/boss/hotel/${hotelId}`)}
              >
                取消調整
              </Button>
            </div>
            <div className="w-[200px] mx-3">
              <Button
                fullWidth
                size="large"
                sx={{ p: "10px", bgcolor: "#00000099", fontSize: "1.5rem" }}
                variant="contained"
                type="submit"
              >
                確認更新
              </Button>
            </div>
          </div>
        )}

        <div className="container max-w-[1024px] mx-auto">
          {uploadImage["hotel.pictures.firstPic"] ? (
            <div className="relative w-full max-h-[768px] aspect-[4/3]">
              <img
                className="w-full h-full aspect-[4/3] object-contain"
                src="https://s1.aigei.com/src/img/gif/af/af31b120297941e4a3e7e07486933cfb.gif?imageMogr2/auto-orient/thumbnail/!282x282r/gravity/Center/crop/282x282/quality/85/%7CimageView2/2/w/282&e=1735488000&token=P7S2Xpzfz11vAkASLTkfHN7Fw-oOZBecqeJaxypL:5CKnVzvOfhYrXB450nTwd2ZwscU="
                alt="hotel view"
              />
            </div>
          ) : (
            <div className="relative w-full max-h-[768px] aspect-[4/3]">
              <img
                className="w-full h-full object-cover"
                src={formik.values.hotel.pictures.firstPic || placeholderImage}
                alt="hotel view"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-slate-500 bg-opacity-50 gap-3">
                <Button
                  className="h-[50px] w-[200px]"
                  sx={{ bgcolor: "#ffffff44" }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUpload />}
                >
                  上傳圖片
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) =>
                      handleImageChange(
                        e.target.files[0],
                        "hotel.pictures.firstPic"
                      )
                    }
                  />
                </Button>
                <Button
                  className="h-[50px] w-[50px]"
                  sx={{ bgcolor: "#ffffff44" }}
                  component="label"
                  variant="contained"
                  onClick={() => handleImageRemove("hotel.pictures.firstPic")}
                >
                  <Delete sx={{ p: "0", m: "0" }} />
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="container max-w-[1024px] h-auto mx-auto grid grid-cols-2">
          <div className="w-full aspect-[4/3] relative  md:col-span-1 col-span-2">
            {uploadImage["hotel.pictures.secondPic"] ? (
              <img
                className="w-full h-full object-cover"
                src="https://www.zilliondesigns.com/blog/wp-content/uploads/finger-tapping-.gif"
                alt="hotel view"
              />
            ) : (
              <div className="relative w-full aspect-[4/3]">
                <img
                  className="w-full h-full object-cover"
                  src={
                    formik.values.hotel.pictures.secondPic || placeholderImage
                  }
                  alt="hotel view"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-slate-500 bg-opacity-50 gap-3">
                  <Button
                    className="h-[50px] w-[200px]"
                    sx={{ bgcolor: "#ffffff44" }}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUpload />}
                  >
                    上傳圖片
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(e) =>
                        handleImageChange(
                          e.target.files[0],
                          "hotel.pictures.secondPic"
                        )
                      }
                    />
                  </Button>
                  <Button
                    className="h-[50px] w-[50px]"
                    sx={{ bgcolor: "#ffffff44" }}
                    component="label"
                    variant="contained"
                    onClick={() =>
                      handleImageRemove("hotel.pictures.secondPic")
                    }
                  >
                    <Delete sx={{ p: "0", m: "0" }} />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div
            className="w-full aspect-[4/3] relative  
          md:col-span-1 col-span-2"
          >
            {uploadImage["hotel.pictures.thirdPic"] ? (
              <div className="relative w-full max-h-[768px] aspect-[4/3]">
                <img
                  className="w-full h-full aspect-[4/3] object-contain"
                  src="https://www.zilliondesigns.com/blog/wp-content/uploads/finger-tapping-.gif"
                  alt="hotel view"
                />
              </div>
            ) : (
              <div className="relative w-full aspect-[4/3]">
                <img
                  className="w-full h-full object-cover"
                  src={
                    formik.values.hotel.pictures.thirdPic || placeholderImage
                  }
                  alt="hotel view"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-slate-500 bg-opacity-50 gap-3">
                  <Button
                    className="h-[50px] w-[200px]"
                    sx={{ bgcolor: "#ffffff44" }}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUpload />}
                  >
                    上傳圖片
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(e) =>
                        handleImageChange(
                          e.target.files[0],
                          "hotel.pictures.thirdPic"
                        )
                      }
                    />
                  </Button>
                  <Button
                    className="h-[50px] w-[50px]"
                    sx={{ bgcolor: "#ffffff44" }}
                    component="label"
                    variant="contained"
                    onClick={() => handleImageRemove("hotel.pictures.thirdPic")}
                  >
                    <Delete sx={{ p: "0", m: "0" }} />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="container max-w-[1024px] mx-auto grid grid-cols-12">
          <div className="col-span-12 sm:col-span-7">
            <div>
              <Tabs value={page} onChange={handleChangePage}>
                <Tab value={0} label="旅店簡介" href="#introduce" />
                <Tab value={1} label="設施與服務" href="#facility" />
                <Tab value={2} label="提供房型" href="#type" />
              </Tabs>
            </div>

            <div className="mt-2 scroll-offset" id="introduce">
              <div className="text-3xl">
                {/* {formik.values.chName} */}
                <TextField
                  size="small"
                  name="hotel.chName"
                  required
                  inputProps={{ maxLength: 10 }}
                  onChange={formik.handleChange}
                  value={formik.values.hotel.chName}
                  placeholder="請輸入飯店名稱..."
                  ariant="outlined"
                  sx={{
                    width: "300px",
                    "& .MuiOutlinedInput-input": {
                      p: "0",
                      m: "0",
                    },
                    input: {
                      color: "#000",
                      fontSize: "30px",
                      height: "36px",
                    },
                  }}
                />
              </div>
              {/* <h2 className="text-sm text-gray-500">{formik.values.enName}</h2> */}
              <TextField
                size="small"
                name="hotel.enName"
                required
                inputProps={{ maxLength: 30 }}
                onChange={formik.handleChange}
                value={formik.values.hotel.enName}
                placeholder="Please enter english name..."
                ariant="outlined"
                sx={{
                  width: "300px",
                  "& .MuiOutlinedInput-input": {
                    p: "0",
                    m: "0",
                  },
                  input: {
                    color: "#6b7280",
                    fontSize: "14px",
                    height: "20px",
                  },
                }}
              />
              {/* <Rating
                sx={{ color: "#023e8a" }}
                size="small"
                name="read-only"
                value="4.5"
                precision={0.5}
                readOnly
                className="py-3"
              /> */}
              <div className="py-3 w-full lg:w-[86%]">
                {/* {formik.values.introduction} */}
                <>
                  <TextField
                    variant="outlined"
                    multiline
                    fullWidth
                    required
                    inputProps={{ maxLength: 220 }}
                    name="hotel.introduction"
                    onChange={formik.handleChange}
                    value={formik.values.hotel.introduction}
                    placeholder="請輸入飯店介紹..."
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        p: "10px",
                      },
                      textarea: {
                        color: "#000",
                        fontSize: "16px",
                        lineHeight: "1.5",
                      },
                    }}
                    rows={8}
                  />
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: "12px",
                      color: "#888",
                    }}
                  >
                    剩餘 {220 - formik.values.hotel.introduction.length} 個字 /
                    220
                  </div>
                </>
              </div>
            </div>
            <div className="mt-5 scroll-offset" id="facility">
              <h2 className="font-bold mb-3">設施與服務</h2>
              <span className="text-sm">(點擊調整旅店內之設施)</span>
              <div className="grid grid-cols-2">
                {facilitySign.map(({ name, icon }, index) => {
                  const isExist =
                    formik.values.hotel.facilities?.includes(name);
                  return (
                    <div
                      onClick={() => handleFacilitySupplyDisplay(name)}
                      className={`cursor-pointer flex col-span-1 m-1 ${
                        isExist ? "" : "text-[#00000044]"
                      }`}
                      key={index}
                    >
                      <div className="pr-3">{icon}</div>
                      <p>{name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-span-12 h-[350px] md:col-span-5 mt-20 ">
            <h2 className="font-bold mb-3">旅店位置</h2>
            <div className="h-10 p-2">
              <p className="text-sm align-text-bottom">
                <FmdGood fontSize="inherit" />
                <span>
                  {formik.values.hotel.address &&
                    AddressArray[formik.values.hotel.address.city][0] +
                      AddressArray[formik.values.hotel.address.city][
                        formik.values.hotel.address.district
                      ] +
                      formik.values.hotel.address.street +
                      formik.values.hotel.address.number}
                </span>
              </p>
              <div className="w-full ">
                <div className="mt-1">
                  <Select
                    fullWidth
                    value={formik.values.hotel.address.city}
                    name="hotel.address.city"
                    onChange={formik.handleChange}
                  >
                    {AddressArray.map((city, index) => (
                      <MenuItem key={index} value={index}>
                        {city[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="mt-3">
                  <Select
                    fullWidth
                    value={formik.values.hotel.address.district}
                    name="hotel.address.district"
                    onChange={formik.handleChange}
                  >
                    {AddressArray[formik.values.hotel.address.city].map(
                      (value, index) => (
                        <MenuItem key={index} value={index}>
                          {value}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
                <div className="mt-3">
                  <OutlinedInput
                    required
                    inputProps={{ maxLength: 15 }}
                    value={formik.values.hotel.address.street}
                    name="hotel.address.street"
                    onChange={formik.handleChange}
                    fullWidth
                    sx={{
                      input: {
                        fontSize: "16px",
                      },
                    }}
                  />
                </div>
                <div className="mt-3">
                  <OutlinedInput
                    required
                    inputProps={{ maxLength: 10 }}
                    value={formik.values.hotel.address.number}
                    name="hotel.address.number"
                    onChange={formik.handleChange}
                    fullWidth
                    sx={{
                      input: {
                        fontSize: "16px",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isEstablishing && (
            <div className="col-span-12 mt-5" id="type">
              <h2 className="font-bold mb-3">提供房型</h2>
              <div className="w-full flex flex-wrap gap-4">
                {formik.values.room?.map((room, index) => (
                  <RoomCards
                    handleUpdateRoomList={null}
                    key={index}
                    room={room}
                    handleOpen={() => handleEditOpen(room, index)}
                  />
                ))}
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#1b263b66",
                    fontSize: "1.5rem",
                    width: "200px",
                  }}
                  onClick={handleCreateOpen}
                >
                  新增房型
                </Button>
              </div>
            </div>
          )}
        </div>
        <Modal keepMounted open={openCreateRoom} onClose={handleCreateClose}>
          <div className="w-[650px] h-auto max-h-[90vh] overflow-y-auto px-6 py-3 rounded-3xl translate-x-[-50%] translate-y-[-50%] absolute top-[50%] left-[50%] bg-[#ffffff]">
            <CreateRoomForm
              handleAddRoom={handleAddRoom}
              handleUpdateRoomList={null}
              close={handleCreateClose}
            />
          </div>
        </Modal>
        <Modal
          sx={{ borderRadius: "1.5rem" }}
          keepMounted
          open={openEditRoom}
          onClose={handleEditClose}
        >
          <div className="w-[500px] h-auto rounded-3xl translate-x-[-50%] translate-y-[-50%] absolute top-[50%] left-[50%] bg-[#ffffff] ">
            <RoomDataCard
              handleClose={handleEditClose}
              room={selectRoom}
              index={selectIndex}
              isEditing={true}
              handleDelete={handleDeleteRoom}
            />
          </div>
        </Modal>
      </div>
    </form>
  );
};
