import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Customer from "./Customer";
import Testimonials from "./Testimonials";
import NavBar from "./Navbar";
import Boss from "./Boss";
import FeaturesCus from "./FeaturesCus";
import FeaturesBoss from "./FeaturesBoss";

export default function LandingPage({ setReadAbout }) {
  return (
    <div className="w-full bg-[#edede9]">
      <NavBar setReadAbout={setReadAbout} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          maxWidth: "1500px",
          minWidth: "500px",
          width: "100%",
          mx: "auto",
          flexDirection: "column",
          bgcolor: "#edede9",
        }}
      >
        <Customer setReadAbout={setReadAbout} />
        <FeaturesCus setReadAbout={setReadAbout} />
        <Boss setReadAbout={setReadAbout} />
        <FeaturesBoss setReadAbout={setReadAbout} />
        <Divider setReadAbout={setReadAbout} />
        <Testimonials setReadAbout={setReadAbout} />
        <Divider setReadAbout={setReadAbout} />
      </Box>
    </div>
  );
}
