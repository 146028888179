import React, { useEffect } from "react";

export const Payment = ({ handleSubmitOrder, loading }) => {
  const APP_ID = process.env.REACT_APP_App_ID;
  const APP_KEY = process.env.REACT_APP_App_Key;

  useEffect(() => {
    // 動態加載 TapPay SDK
    const loadTapPaySDK = () => {
      const script = document.createElement("script");
      script.src = "https://js.tappaysdk.com/sdk/tpdirect/v5.14.0";
      script.async = true;
      script.onload = () => {
        // SDK 載入完成後初始化
        window.TPDirect.setupSDK(APP_ID, APP_KEY, "sandbox");

        // 設置信用卡表單
        window.TPDirect.card.setup({
          fields: {
            number: {
              element: "#card-number",
              placeholder: "**** **** **** ****",
            },
            expirationDate: {
              element: "#card-expiration-date",
              placeholder: "MM / YY",
            },
            ccv: {
              element: "#card-ccv",
              placeholder: "後三碼",
            },
          },
          styles: {
            input: {
              color: "black",
              "background-color": "#000",
            },
            ":focus": {
              color: "black",
            },
            ".valid": {
              color: "green",
            },
            ".invalid": {
              color: "red",
            },
          },
          isMaskCreditCardNumber: true,
          maskCreditCardNumberRange: {
            beginIndex: 6,
            endIndex: 11,
          },
        });

        // 監聽表單狀態變化
        window.TPDirect.card.onUpdate(function (update) {
          // 卡片資訊是否完整
          if (update.canGetPrime) {
            // 啟用提交按鈕
          } else {
            // 停用提交按鈕
          }
        });
      };
      document.body.appendChild(script);
    };

    loadTapPaySDK();

    // 清理函數
    return () => {
      const script = document.querySelector(
        'script[src="https://js.tappaysdk.com/sdk/tpdirect/v5.14.0"]'
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [APP_ID, APP_KEY]);

  const handleSubmit = () => {
    // 取得 TapPay Fields 的 status
    const tappayStatus = window.TPDirect.card.getTappayFieldsStatus();

    // 確認是否可以 getPrime
    if (tappayStatus.canGetPrime === false) {
      alert("請確認信用卡資訊完整");
      return;
    }

    // Get prime
    window.TPDirect.card.getPrime((result) => {
      if (result.status !== 0) {
        alert("信用卡認證取得失敗");
        return;
      }
      // alert("取得 prime 成功，prime: " + result.card.prime);
      // 這邊你可以送 prime 到你的後端
      handleSubmitOrder(result.card.prime);
    });
  };

  return (
    <div className="p-4 text-black w-full h-[500px]">
      <div className="max-w-md mx-auto">
        <h2 className="text-xl font-bold mb-4">信用卡付款</h2>
        <div className="space-y-4 ">
          <div className="space-y-2">
            <label className="block text-sm font-medium">卡號</label>
            <div
              id="card-number"
              className="h-10 rounded-md border border-gray-300 px-3 py-2"
            />
          </div>
          <div>測試卡號：4242 4242 4242 4242</div>

          <div className="space-y-2">
            <label className="block text-sm font-medium">到期日</label>
            <div
              id="card-expiration-date"
              className="h-10 rounded-md border border-gray-300 px-3 py-2"
            />
          </div>
          <div>測試到期日：01 / 25</div>

          <div className="space-y-2">
            <label className="block text-sm font-medium">安全碼</label>
            <div
              id="card-ccv"
              className="h-10 rounded-md border border-gray-300 px-3 py-2"
            />
          </div>
          <div>測試安全碼： 123</div>

          <button
            onClick={handleSubmit}
            className="w-full bg-blue-900 text-white py-2 px-4 rounded-md hover:bg-blue-800 transition-colors"
          >
            {loading ? (
              <img
                className="w-[30px] h-[30px] mx-auto"
                src="https://cdn.pixabay.com/animation/2023/11/09/03/05/03-05-45-320_512.gif"
                alt="loading"
              />
            ) : (
              <p className="m-0"> 確認付款</p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
