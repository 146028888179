import { Box, Container, Grid, Typography, Button } from "@mui/material";
import profits from "../assets/profits.png";
import living from "../assets/living.png";
import houses from "../assets/houses.png";
import { useNavigate } from "react-router-dom";

export default function FeaturesBoss({ setReadAbout }) {
  const navigate = useNavigate();


  const handleClick = () => {
    const read = localStorage.getItem("read");
    if (!read) {
      localStorage.setItem("read", "yes");
      setReadAbout("yes");
      navigate("/");
    } else {
      navigate("/");
    }
  };
  return (
    <Container
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: "100%",

          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          variant="h3"
          color="text.primary"
          sx={{
            mb: 4,
            fontSize: { xs: 25, md: 36 },
            lineHeight: 1.5,
            fontWeight: 450,
            mx: 2,
          }}
        >
          透過<b> SevenTao</b>
          <br />
          您可以輕鬆將空房轉化為額外的收入來源！
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              src={living}
              sx={{
                height: "300px",
                px: 4,
                pt: "35px",
                pb: "35px",
                pointerEvents: "none",
              }}
            />
            <Typography variant="h6" color="black" sx={{ mb: "20px" }}>
              投資花費
            </Typography>
            <Typography variant="body2" color="black" sx={{ mx: 2, mb: 2 }}>
              房子的內裝、擺設，像是電視、沙發，甚至遊樂器等奢侈品，都會成為吸引房客的資本。
              提供更優質的服務與設備，不僅能提高租金，自己也能在使用時享受更好的居住體驗。
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              pointerEvents: "none",
            }}
          >
            <Box
              component="img"
              src={profits}
              sx={{ height: 300, px: 3, pt: 3, pb: "30px", mb: "4px" }}
            />
            <Typography variant="h6" color="black" sx={{ mb: 2 }}>
              額外收入
            </Typography>
            <Typography variant="body2" color="black" sx={{ mx: 2, mb: 2 }}>
              將房間或房子出租，可以讓閒置的空間變成收入來源，每月穩定增加現金流。
              這種方式能讓資金更靈活運用，減輕經濟壓力的同時提升生活品質。
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              src={houses}
              sx={{
                height: 280,
                py: 3,
                pt: "15px",
                pb: "20px",
                mb: "20px",
                pointerEvents: "none",
              }}
            />
            <Typography variant="h6" color="black" sx={{ mb: "20px" }}>
              彈性使用
            </Typography>
            <Typography variant="body2" color="black" sx={{ mx: 2, mb: 2 }}>
              當親友或家人到附近旅遊或出差時，房子的短租模式能靈活調整使用權。
              相較於長期出租，這種安排讓空間保留更多彈性，兼顧收益與自用需求。
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          backgroundColor: "#343a40",
          ":hover": {
            backgroundColor: "#343a40aa",
          },
          height: 50,
          width: 110,
          fontSize: 18,

          whiteSpace: "nowrap",
        }}
      >
        立即體驗
      </Button>
    </Container>
  );
}
