import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardMedia,
  Grid,
  Modal,
  Rating,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import {
  Pool,
  EvStation,
  FitnessCenter,
  SmokeFree,
  DirectionsCar,
  SupportAgent,
  Restaurant,
  FmdGood,
} from "@mui/icons-material";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";

import { useNavigate, useParams } from "react-router-dom";
import { AddressArray } from "../config/AddressData";
import { CommendCard } from "../cards/CommentCard";
import { TabContext } from "@mui/lab";
import {
  fetchBossRoom,
  fetchComment,
  fetchHotel,
  getLocation,
} from "./HotelPageFetch";
import { RoomCards } from "../cards/RoomCards";
import { RoomDataCard } from "./RoomDataCard";
import { getAuthToken } from "../config/config";
import { fetchGetUserData } from "../member/profile/ProfileDataFetch";
import { Mapbox } from "../cards/Map";

export const BossHotelPage = () => {
  const jwt = getAuthToken();
  const [open, setOpen] = useState(false);
  const [selectRoom, setSelectRoom] = useState();

  const handleOpen = (room) => {
    setOpen(true);
    setSelectRoom(room);
  };
  const handleClose = () => setOpen(false);
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [hotelData, setHotelData] = useState();
  const [roomData, setRoomData] = useState();
  const [userData, setUserData] = useState();
  const [comments, setComments] = useState();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (jwt)
      fetchGetUserData(jwt).then((data) => {
        setUserData(data);
      });
  }, [jwt]);
  useEffect(() => {
    fetchHotel(jwt, hotelId).then((data) => {
      if (data) setHotelData(data);
    });
    fetchBossRoom(jwt, hotelId).then((data) => {
      if (data) setRoomData(data);
    });
    fetchComment(hotelId).then((comment) => {
      console.log("取得評論");
      console.log(comment);
      setComments(comment);
    });
  }, []);

  const facilitySign = [
    { name: "游泳池", icon: <Pool /> },
    { name: "充電樁", icon: <EvStation /> },
    { name: "健身房", icon: <FitnessCenter /> },
    { name: "禁菸", icon: <SmokeFree /> },
    { name: "停車場", icon: <DirectionsCar /> },
    { name: "全時櫃檯", icon: <SupportAgent /> },
    { name: "早餐", icon: <Restaurant /> },
  ];

  if (!hotelData) {
    return (
      <div className="flex items-center justify-center h-screen bg-black">
        <img
          className="max-w-full max-h-full object-contain"
          src="https://i.redd.it/ubbi1p7z7euc1.gif"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-[1024px]">
      <div className="fixed flex right-[200px] bottom-[100px] z-50">
        <div className="w-[200px] mx-3">
          <Button
            fullWidth
            size="large"
            sx={{ p: "10px", bgcolor: "#00000099", fontSize: "1.5rem" }}
            variant="contained"
            onClick={() => navigate(`/boss/hotels/${hotelId}/adjust`)}
          >
            編輯內容
          </Button>
        </div>
      </div>

      <div className="aspect-[4/3] mx-auto">
        <img
          className="w-full h-full object-cover"
          src={
            hotelData?.pictures.length > 0
              ? hotelData.pictures[0]
              : "https://www.vanorohotel.com/wp-content/uploads/2021/07/drz-vanoro_6737.jpg"
          }
          alt="hotel view"
        />
      </div>
      <div className="grid grid-cols-2">
        <div className="aspect-[4/3] md:col-span-1 col-span-2">
          <img
            className="w-full h-full object-cover"
            src={
              hotelData?.pictures.length > 0
                ? hotelData.pictures[1]
                : "https://www.vanorohotel.com/wp-content/uploads/2021/07/drz-vanoro_6737.jpg"
            }
            alt="hotel view"
          />
        </div>
        <div className="aspect-[4/3] md:col-span-1 col-span-2">
          <img
            className="w-full h-full object-cover"
            src={
              hotelData?.pictures.length > 0
                ? hotelData.pictures[2]
                : "https://www.vanorohotel.com/wp-content/uploads/2021/07/drz-vanoro_6737.jpg"
            }
            alt="hotel view"
          />
        </div>
      </div>
      <TabContext value={tabValue}>
        <div className="container mx-auto grid grid-cols-12 p-2 max-w-[1024px]">
          <div className="col-span-12 md:col-span-8">
            <div>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab value={0} label="旅店簡介" href="#introduce" />
                <Tab value={1} label="設施與服務" href="#facility" />
                <Tab value={2} label="提供房型" href="#type" />
                <Tab value={3} label="旅店評論" href="#discussion" />
              </Tabs>
            </div>

            <div className="mt-5 scroll-offset" id="introduce">
              <h1 className="text-5xl">{hotelData?.chName}</h1>
              <h2 className="text-xl text-gray-500">{hotelData?.enName}</h2>
              {!hotelData?.score == 0 && (
                <Rating
                  sx={{ color: "#023e8a" }}
                  size="medium"
                  name="read-only"
                  value={Number(hotelData?.score) || 0}
                  precision={0.5}
                  readOnly
                />
              )}
              <p
                style={{
                  resize: "none",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
                className="w-[95%] text-xl"
              >
                {hotelData?.introduction}
              </p>
            </div>
            <div className="mt-5 scroll-offset" id="facility">
              <h2 className="font-bold mb-3">設施與服務</h2>
              <div className="grid grid-cols-2">
                {facilitySign
                  .filter(
                    ({ name }) =>
                      hotelData?.facilities &&
                      hotelData?.facilities.includes(name)
                  )
                  .map(({ name, icon }, index) => (
                    <div key={index} className="flex">
                      <div className="pr-3 flex">{icon}</div>
                      <p className="text-[18px]">{name}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4 md:mt-[130px]">
            <h2 className="font-bold mb-3">旅店位置</h2>
            <Card sx={{ borderRadius: "20px" }}>
              <CardMedia>
                {
                  <Mapbox
                    lat={hotelData?.location && hotelData.location[1]}
                    lon={hotelData?.location && hotelData.location[0]}
                    chName={hotelData?.chName}
                    enName={hotelData?.enName}
                  />
                }
              </CardMedia>
              <div className="h-10 p-2">
                <p className="text-sm align-text-bottom">
                  <FmdGood fontSize="inherit" />{" "}
                  <span>
                    {AddressArray[hotelData?.address.city][0] +
                      AddressArray[hotelData?.address.city][
                        hotelData.address.district
                      ] +
                      hotelData?.address.street +
                      hotelData?.address.number}
                  </span>
                </p>
              </div>
            </Card>
          </div>
          <div className="col-span-12 mt-5 scroll-offset" id="type">
            <h2 className="font-bold mb-3">可提供房型</h2>
            <div className="w-full flex flex-wrap gap-4">
              {roomData && roomData.length > 0 ? (
                roomData.map((room, index) => (
                  <div key={index}>
                    <RoomCards
                      handleOpen={() => handleOpen(room)}
                      key={index}
                      room={room}
                    />
                  </div>
                ))
              ) : (
                <p>目前沒有可用的房間</p>
              )}
            </div>
          </div>

          <div className="col-span-12 mt-5 scroll-offset" id="discussion">
            <h2 className="font-bold mb-3">旅店評論</h2>

            <div className="flex flex-wrap justify-between gap-3">
              {comments && comments.length > 0 ? (
                [...comments].reverse().map((commentData, index) => (
                  <div key={index} className="md:w-[48%] w-full">
                    <CommendCard
                      commentData={commentData}
                      isEditing={false}
                      userData={userData}
                    />
                  </div>
                ))
              ) : (
                <p>尚無評論</p>
              )}
            </div>
          </div>
        </div>
        <Modal
          sx={{ borderRadius: "1.5rem" }}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <div className="w-[500px] h-auto rounded-3xl translate-x-[-50%] translate-y-[-50%] absolute top-[50%] left-[50%] bg-[#ffffff] ">
            <RoomDataCard
              isBoss={true}
              handleClose={handleClose}
              room={selectRoom}
            />
          </div>
        </Modal>
      </TabContext>
    </div>
  );
};
