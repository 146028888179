import { api, api_hotel } from "../config/config";
export const fetchAllHotels = async () => {
  try {
    const hotelsAll = await api_hotel.get(`/find`);
    if (hotelsAll?.data.status == 200) {
      console.log(hotelsAll.data);
      return hotelsAll.data.data;
    }
    console.log(hotelsAll.data.message);
  } catch (error) {
    console.log("Error fetching hotels:", error);
  }
};

export const fetchGetCityList = async () => {
  try {
    const cities = await api_hotel.get(`/hotelAddress`);
    if (cities?.data.status == 200) {
      console.log(cities.data.message);
      return cities.data.data;
    }
    console.log(cities.data.message);
  } catch (error) {
    console.log("Error fetching cities:", error);
  }
};
export const fetchGetHotelByDetail = async ({
  city,
  start,
  end,
  people,
  keyword,
}) => {
  try {
    const hotels = await api_hotel.get(
      `/find?cityCode=${city}&start=${start}&end=${end}&people=${people}&keyword=${keyword}`
    );
    if (hotels?.data.status == 200) {
      console.log(hotels.data.message);
      return hotels.data.data;
    }
    console.log(hotels.data.message);
  } catch (error) {
    console.log("Error fetching hotels:", error);
  }
};
