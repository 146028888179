import { Avatar, Button, Rating, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { fetchAddComment, fetchDeleteComment } from "./UserFunctionFetch";
import CloseIcon from "@mui/icons-material/Close";
import { getAuthToken } from "../config/config";

export const CommendCard = ({
  order,
  userData,
  commentData,
  handleClose,
  isEditing,
}) => {
  const jwt = getAuthToken();
  const handleDeleteComment = (commentId) => {
    fetchDeleteComment(jwt, commentId);
  };

  const formik = useFormik({
    initialValues: {
      rate: 0,
      recommend: "",
    },
    onSubmit: (values) => {
      const comment = {
        rate: values.rate,
        comment: values.recommend,
        roomId: order?.roomId,
        orderId:order?.id
      };
      console.log(comment);

      fetchAddComment(jwt, comment).then((data) => {
        if (data) handleClose();
      });
    },
  });

  return (
    <div className="w-full">
      {isEditing ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="rounded-3xl bg-gray-300 px-4 pb-4 pt-3 h-auto">
            <div className="flex justify-between mb-2">
              <div className="flex sm:flex">
                <Avatar src={userData?.photo} />
                <span className="mx-3 mt-1 text-2xl hidden sm:block">
                  {userData?.nickName ||
                    userData?.firstName ||
                    userData?.laseName}
                </span>
                <Rating
                  required
                  name="rate"
                  className="mt-2"
                  value={Number(formik.values.rate) || 0}
                  onChange={(event, newValue) => {
                    if (newValue < 1) {
                      formik.setFieldValue("rate", 1);
                    }
                    formik.setFieldValue("rate", newValue);
                  }}
                />
              </div>
              <div>
                <Button
                  sx={{ bgcolor: "#124559" }}
                  type="submit"
                  variant="contained"
                >
                  提交
                </Button>
              </div>
            </div>
            <p>
              住宿資訊
              <br />
              入住時間：{order?.checkInDate} ~ {order?.checkOutDate}
              <br />
              入住房型：{order?.roomName}
            </p>
            <div>
              <TextField
                size="small"
                name="recommend"
                multiline
                required
                fullWidth
                minRows={5}
                inputProps={{ maxLength: 25 }}
                onChange={formik.handleChange}
                value={formik.values.recommend}
                placeholder="評價本次住宿體驗？ (限２５個字)"
                variant="outlined"
                sx={{
                  bgcolor: "white",
                  "& .MuiOutlinedInput-root": {
                    p: "10px",
                    m: "0",
                  },
                }}
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="flex-col bg-gray-300 p-2 rounded-2xl w-full">
          <div className="flex justify-between">
            <div className="flex p-1">
              <Avatar src={commentData?.userPhoto} />
              <span className="mx-3 mb-0 mt-1 text-2xl">
                {commentData?.userName}
              </span>
            </div>
            <div>
              <div className="w-5 h-5 ml-auto">
                {commentData?.userId === userData?.userId && (
                  <div
                    onClick={() => handleDeleteComment(commentData.commentId)}
                  >
                    <CloseIcon className="cursor-pointer" />
                  </div>
                )}
              </div>
              <div>
                <Rating value={Number(commentData?.rate)} readOnly />
              </div>
            </div>
          </div>

          <div className="mb-1 flex-col justify-self-end">
            <p className="m-0 text-gray-500">
                住宿時間：{ commentData?.livingTime}
            </p>
              <p className="m-0 text-gray-500">住宿房型：{ commentData?.roomType}</p>
          </div>

          <div className="bg-gray-100 rounded-2xl text-xl mx-1 mb-1">
            <p
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="px-3 py-2 text-[16px] m-0 "
            >
              {commentData?.comment}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
