import React, { useEffect, useState } from "react";

import { Route, Routes } from "react-router-dom";
import { Navigator } from "../visitor/Navigator";
import { HotelPage } from "../visitor/HotelPage";
import { Home } from "../visitor/Home";
import { fetchGetUserData } from "../member/profile/ProfileDataFetch";
import { getAuthToken } from "../config/config";

export const VisitorRouter = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Navigator setOpen={setOpen} open={open} />

      <div className="mt-14  bg-[#edede9] min-h-[100vh]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/hotel/:hotelId"
            element={<HotelPage setOpenSignIn={setOpen} />}
          />
          <Route path="/logout" />
        </Routes>
      </div>
    </div>
  );
};
