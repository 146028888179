import { useNavigate } from "react-router-dom";
import { api, api_user } from "../config/config";
import Cookies from "universal-cookie";

const navigateToBase = (navigate, role) => {
  if (role === "ROLE_ADMIN") navigate("/admin");
  else if (role === "ROLE_HOTELER") navigate("/boss/hotels");
  else navigate("/");
  window.location.reload();
};

export const fetchSignIn = async (setWait, navigate, data) => {
  const cookie = new Cookies();
  const setToken = (token) => {
    cookie.set("jwt", token, { path: "/", secure: true, sameSite: true });
    console.log(cookie.get("jwt"));
  };

  try {
    // setWait(true);
    const userData = await api_user.post(`/signin`, data);
    if (userData && userData.data) {
      if (userData.data.data != null) {
        console.log(userData.data.message);
        console.log(userData.data.data.jwt);

        setToken(userData.data.data.jwt);
        navigateToBase(navigate, userData.data.role);
        alert("登入成功");
        setWait(false);
        // window.location.reload();
        return userData.data.data;
      }
      console.log(userData.data.message);
      setWait(false);
      return userData.data.message;
    } else {
      setWait(false);

      console.error("Invalid data:", userData);
    }
  } catch (error) {
    setWait(false);

    console.error("Login failed:", error?.response?.data || error.message);
  }
};

export const fetchSignUp = async (setWait, navigate, data) => {
  const cookie = new Cookies();
  const setToken = (token) => {
    cookie.set("jwt", token, { path: "/", secure: true, sameSite: true });
    console.log(cookie.get("jwt"));
  };
  try {
    // setWait(true);
    const userData = await api_user.post(`/signup`, data);
    if (userData && userData.data) {
      console.log(userData.data);
      if (userData.data.data != null) {
        // console.log(userData.data.data.jwt);
        console.log(userData.data);
        
        setToken(userData.data.data.jwt);
        setWait(false);
        alert(userData.data.message);
        // navigate("/");
        return " ";

      }
      setWait(false);
      return userData.data.message;
    } else {
      setWait(false);
      console.error("Invalid data:", userData);
    }
  } catch (error) {
    setWait(false);
    console.log("Error signup value:", error);
  }
};
export const fetchLogOut = (navigate) => {
  const cookie = new Cookies();
  cookie.remove("jwt");
  cookie.remove("searchParam");
  sessionStorage.clear();
  navigate("/");
  window.location.reload();
};
