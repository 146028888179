import React, { useEffect, useState } from "react";
import { HistoryRoomCard } from "../../cards/HistoryRoomCard";
import { Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { fetchGetHotelsOrders, fetchGetHotelsRooms } from "../HotelPageFetch";
import { getAuthToken } from "../../config/config";

export const BossOrderHistory = () => {
  const jwt = getAuthToken();
  const [hotels, setHotels] = useState();
  const [hotelOrders, setHotelOrders] = useState(); // 初始化為空數組
  const [value, setValue] = useState(0);

  useEffect(() => {
    fetchGetHotelsRooms(jwt)
      .then((hotelRoom) => {
        setHotels(hotelRoom);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, [jwt]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    fetchGetHotelsOrders(jwt, hotels).then((orders) => {
      setHotelOrders(orders);
    });
  }, [jwt, hotels]);

  const OrderSection = ({ title, orders, cardProps, emptyMessage }) => (
    <div className="mt-5">
      <h1 className="pb-3 text-xl font-bold">{title}</h1>
      <div className="flex flex-wrap gap-4 min-w-96">
        {Array.isArray(orders) && orders.length > 0 ? (
          orders.map((order) => (
            <HistoryRoomCard key={order.id} order={order} {...cardProps} />
          ))
        ) : (
          <div className="text-xl font-semibold">{emptyMessage}</div>
        )}
      </div>
    </div>
  );

  if (!hotels) return <h1 className="m-10">loading hotels</h1>;

  return (
    <div className="mt-20">
      <TabContext value={value.toString()}>
        {hotels?.length > 0 ? ( // 使用 > 0 判斷
          <Tabs
            value={value.toString()}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "black", // 底線顏色設定為黑色
              },
            }}
          >
            {hotels?.map((hotel, index) => (
              <Tab
                value={index.toString()}
                label={hotel.hotelName}
                key={index}
                sx={{
                  color: "black",
                  px: "5",
                  mr: "25px",
                  fontSize: "25px",
                  fontWeight: "bold",
                  underLine: "black",
                  "&.Mui-selected": { color: "black" },
                }}
              />
            ))}
          </Tabs>
        ) : (
          <div className="text-xl font-semibold">目前尚無旅店</div>
        )}
        <div>
          {!hotelOrders ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div>
              {hotelOrders.map(
                ([valid, pending, disannul, finish, cancel], index) => (
                  <TabPanel value={index.toString()} key={index}>
                    <OrderSection
                      title="有效訂單："
                      orders={[...valid, ...disannul]}
                      cardProps={{ isClient: false }}
                      emptyMessage="目前無未有效訂單"
                    />
                    <OrderSection
                      title="待審核訂單："
                      orders={pending}
                      cardProps={{ isClient: false }}
                      emptyMessage="目前無待審核訂單"
                    />
                    <OrderSection
                      title="已完成訂單："
                      orders={finish}
                      cardProps={{ isClient: false, finished: true }}
                      emptyMessage="目前無歷史訂單"
                    />
                    <OrderSection
                      title="無效訂單："
                      orders={cancel}
                      cardProps={{ isClient: false, canceled: true }}
                      emptyMessage="目前無無效訂單"
                    />
                  </TabPanel>
                )
              )}
            </div>
          )}
        </div>
      </TabContext>
    </div>
  );
};
