import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Divider, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import { fetchSignUp } from "./UserSignInUpFetch";
import { useNavigate } from "react-router-dom";

export const SignupForm = ({ setWait, handleChangeSignForm, handleClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const formik = useFormik({
    initialValues: {
      password: "",
      lastName: "",
      firstName: "",
      email: "",
      phoneNum: "",
    },

    onSubmit: (values) => {
      setLoading(true);
      setEmailError(null);
      setPhoneError(null);
      if (!values.phoneNum.startsWith("09") || values.phoneNum.length != 10) {
        return setPhoneError("請輸入正確手機號碼格式０９ＸＸＸＸＸＸＸＸ");
      }
      const trimedValue = {
        password: values.password,
        lastName: values.lastName.trim(),
        firstName: values.firstName.trim(),
        email: values.email,
        phoneNum: values.phoneNum.trim(),
      };
      fetchSignUp(setWait, navigate, trimedValue)
        .then((data) => {
          console.log(data);
          if (data == " ") handleClose();

          if (data.startsWith("此信箱已註冊過")) setEmailError(data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="max-w-[1024px]">
      <h1 className="text-3xl font-bold pl-5">註冊</h1>
      <form
        autoComplete="none"
        className="grid grid-cols-12 w-full p-2 gap-2"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          className="md:col-span-7 col-span-12"
          sx={{
            "& .MuiInputLabel-root": { color: "gray" },
            "& .MuiInputLabel-root.Mui-focused": { color: "black" },
            "& .MuiFilledInput-root": {
              backgroundColor: "#fcfcfc",
              "&:before": { borderBottom: "none" },
              "&:after": { borderBottom: "none" },
            },
            "& .MuiInputBase-input": { color: "black" },
          }}
          required
          type="email"
          inputProps={{ maxLength: 40 }}
          name="email"
          label="電子郵件"
          size="small"
          error={emailError != null}
          helperText={emailError}
          value={formik.values.email}
          onChange={formik.handleChange}
          variant="filled"
        />
        <div className=" bg-[#fcfcfc] flex justify-between md:col-span-5 col-span-12">
          <TextField
            required
            inputProps={{
              maxLength: 15,
              autoComplete: "new-password",
            }}
            sx={{
              "& .MuiInputLabel-root": { color: "gray" },
              "& .MuiInputLabel-root.Mui-focused": { color: "black" },
              "& .MuiFilledInput-root": {
                backgroundColor: "#fcfcfc00",
                "&:before": { borderBottom: "none" },
                "&:after": { borderBottom: "none" },
              },
              "& .MuiInputBase-input": { color: "black" },
            }}
            type={showPassword ? "text" : "password"}
            name="password"
            label="密碼"
            size="small"
            variant="filled"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <IconButton
            onClick={handleClickShowPassword}
            edge="end"
            sx={{
              p: "0",
              m: "0",
              ":hover": {
                bgcolor: "#fcfcfc00",
              },
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>

        <TextField
          required
          className="md:col-span-6  col-span-12"
          inputProps={{ maxLength: 15 }}
          sx={{
            "& .MuiInputLabel-root": { color: "gray" }, // Label color
            "& .MuiInputLabel-root.Mui-focused": { color: "black" }, // Focused label color
            "& .MuiFilledInput-root": {
              backgroundColor: "#fcfcfc", // 修改填充背景顏色
              "&:before": { borderBottom: "none" }, // Default underline border (before focus)
              "&:after": { borderBottom: "none" }, // Focused underline border (after focus)
            },
            "& .MuiInputBase-input": { color: "black" }, // Input text color}}
          }}
          name="lastName"
          label="姓氏"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          size="small"
          variant="filled"
        />
        <TextField
          className="md:col-span-6 col-span-12"
          sx={{
            "& .MuiInputLabel-root": { color: "gray" }, // Label color
            "& .MuiInputLabel-root.Mui-focused": { color: "black" }, // Focused label color
            "& .MuiFilledInput-root": {
              backgroundColor: "#fcfcfc", // 修改填充背景顏色
              "&:before": { borderBottom: "none" }, // Default underline border (before focus)
              "&:after": { borderBottom: "none" }, // Focused underline border (after focus)
            },
            "& .MuiInputBase-input": { color: "black" }, // Input text color}}
          }}
          name="firstName"
          inputProps={{ maxLength: 20 }}
          label="名字"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          size="small"
          variant="filled"
        />

        <TextField
          required
          className="col-span-12"
          sx={{
            "& .MuiInputLabel-root": { color: "gray" }, // Label color
            "& .MuiInputLabel-root.Mui-focused": { color: "black" }, // Focused label color
            "& .MuiFilledInput-root": {
              backgroundColor: "#fcfcfc", // 修改填充背景顏色
              "&:before": { borderBottom: "none" }, // Default underline border (before focus)
              "&:after": { borderBottom: "none" }, // Focused underline border (after focus)
            },
            "& .MuiInputBase-input": { color: "black" }, // Input text color}}
          }}
          inputProps={{ maxLength: 10 }}
          name="phoneNum"
          type="tel"
          label="聯絡電話"
          value={formik.values.phoneNum}
          error={phoneError != null}
          helperText={phoneError}
          onChange={(e) => {
            // 去除非數字並限制長度
            const inputValue = e.target.value
              .replace(/[^0-9]/g, "")
              .slice(0, 10);
            formik.setFieldValue("phoneNum", inputValue);
          }}
          size="small"
          variant="filled"
        />

        <Button
          sx={{
            mt: "5px",
            bgcolor: "#1e6091",
            padding: "4px",
            fontSize: "1.1rem",
          }}
          type="submit"
          fullWidth
          className="col-span-12 "
          variant="contained"
        >
          {loading ? (
            <img
              className="w-[30px] h-[30px]"
              src="https://cdn.pixabay.com/animation/2023/11/09/03/05/03-05-45-320_512.gif"
              alt="loading"
            />
          ) : (
            <p>註冊</p>
          )}
        </Button>
      </form>
      <div className="col-span-12 text-center">
        <h2 className="text-sm font-normal m-0">
          已經有帳號了？
          <span
            className="cursor-pointer font-bold underline"
            onClick={handleChangeSignForm}
          >
            立即登入
          </span>
        </h2>
      </div>

      <Divider sx={{ color: "gray" }}> or </Divider>

      <div className="mx-2">
        <Button
          sx={{
            my: "8px",
            borderColor: "#1e6091",
            color: "#1e6091",
          }}
          fullWidth
          variant="outlined"
          disabled
        >
          <img
            className="mr-2 h-[20px]"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png"
            alt="google logo"
          />
          用 Google 註冊
        </Button>
      </div>
    </div>
  );
};
