import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ProfileBase } from "../member/profile/ProfileBase";
import { ProfileFavorite } from "../member/profile/ProfileFavorite";
import { ProfileData } from "../member/profile/ProfileData";
import { ProfileHistory } from "../member/profile/ProfileHistory";
import { getAuthToken } from "../config/config";
export const MemberRouter = () => {
  const jwt = getAuthToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (!jwt) {
      alert("你無權訪問此網頁");
      navigate("/");
    }
  }, [jwt]);
  return (
    <div className="">
      <div>
        <ProfileBase />
      </div>
      <div className="py-[100px] lg:pl-[250px] mx-10">
        <Routes>
          <Route path="/favorite" element={<ProfileFavorite />} />
          <Route path="/profile" element={<ProfileData />} />
          <Route path="/history" element={<ProfileHistory />} />
        </Routes>
      </div>
    </div>
  );
};
