import axios from "axios";
import Cookies from "universal-cookie";

// export const API_URL = "http://localhost:8081";

// export const api = axios.create({
//   baseURL: API_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const URL = "https://api.seven7tao.com";

export const api_user = axios.create({
  baseURL: URL + "/user-service",
  headers: {
    "Content-Type": "application/json",
  },
});
export const api_hotel = axios.create({
  baseURL: URL + "/hotel-user",
  headers: {
    "Content-Type": "application/json",
  },
});
export const api_room = axios.create({
  baseURL: URL + "/room-user",
  headers: {
    "Content-Type": "application/json",
  },
});
export const api_order = axios.create({
  baseURL: URL + "/order-user",
  headers: {
    "Content-Type": "application/json",
  },
});
export const api_comment = axios.create({
  baseURL: URL + "/comment-service",
  headers: {
    "Content-Type": "application/json",
  },
});
export const api_boss_hotel = axios.create({
  baseURL: URL + "/hotel-admin",
  headers: {
    "Content-Type": "application/json",
  },
});
export const api_boss_room = axios.create({
  baseURL: URL + "/room-admin",
  headers: {
    "Content-Type": "application/json",
  },
});
export const api_boss_order = axios.create({
  baseURL: URL + "/order-admin",
  headers: {
    "Content-Type": "application/json",
  },
});

export const getAuthToken = () => {
  const cookie = new Cookies();

  if (cookie.get("jwt") === undefined) {
    return "";
  }
  return cookie.get("jwt");
};
