import React, { useState } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";
import house from "../assets/house.png";
export function Mapbox({ lat, lon, chName, enName }) {
  const MAPBOX_TOKEN = process.env.REACT_APP_MapBoxToken;

  const [viewState, setViewState] = useState({
    longitude: lon || 121.533176,
    latitude: lat || 25.064763,
    zoom: 16,
  });

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Map
        {...viewState} // 使用 viewState 控制地圖中心點與縮放
        onMove={(evt) => setViewState(evt.viewState)} // 允許拖動地圖時更新視圖
        style={{ width: "100%", height: "100%" }} // 地圖容器樣式
        mapStyle="mapbox://styles/mapbox/streets-v11" // 使用 Mapbox 提供的樣式
        mapboxAccessToken={MAPBOX_TOKEN} // Mapbox 的 API 金鑰
      >
        <Marker
          longitude={lon || 121.533176}
          latitude={lat || 25.064763}
          anchor="center"
        >
          <div className="flex-col justify-items-center p-3 rounded-3xl bg-[#ffffffaa]">
            <img width="50px" src={house} alt="" />
            <p className="text-2xl m-0 text-black font-bold">{chName}</p>
            <p className="text-1xl m-0 text-black font-bold">{enName}</p>
          </div>
        </Marker>
      </Map>
    </div>
  );
}
export function geocoderAddress(address) {
  const MAPBOX_TOKEN = process.env.REACT_APP_MapBoxToken;
  return new Promise((resolve) => {
    axios({
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json`,
      method: "GET",
      params: {
        access_token: MAPBOX_TOKEN,
      },
    }).then((res) => {
      if (res.status == 200) {
        let center = null;
        res.data.features.map((p) => {
          if (!center) {
            center = p.center;
          }
        });
        resolve(center);
      }
    });
  });
}
