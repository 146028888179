import React, { useEffect, useState } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
import { BossHotelCreate } from "../boss/BossHotelCreate";
import { BossProfileBase } from "../boss/profile/BossProfileBase";
import { BossHotelList } from "../boss/profile/BossHotelList";
import { BossOrderHistory } from "../boss/profile/BossOrderHistory";
import { BossHotelPage } from "../boss/BossHotelPage";
import { fetchGetUserData } from "../member/profile/ProfileDataFetch";
import { getAuthToken } from "../config/config";
export const BossRouter = () => {
  const jwt = getAuthToken();
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (jwt)
      fetchGetUserData(jwt).then((data) => {
        if (data && data?.role != "ROLE_HOTELER") {
          alert("你無權訪問此網頁");
          navigate("/");
        }
        setUserData(data);
      });
  }, [jwt]);

  return (
    <div>
      <div>
        <BossProfileBase />
      </div>
      <div className="mt-16 lg:pl-[250px]">
        <Routes>
          <Route
            path="/hotels"
            element={<BossHotelList userData={userData} />}
          />
          <Route
            path="/hotel/create"
            element={<BossHotelCreate isEstablishing={true} />}
          />
          <Route
            path="/hotels/:hotelId/adjust"
            element={<BossHotelCreate isEstablishing={false} />}
          />
          <Route
            path="/hotel/:hotelId"
            element={<BossHotelPage userData={userData} />}
          />
          <Route path="/hotels/history" element={<BossOrderHistory />} />
        </Routes>
      </div>
    </div>
  );
};
