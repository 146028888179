import React, { useEffect, useState } from "react";
import { HistoryRoomCard } from "../../cards/HistoryRoomCard";
import {
  fetchDeleteInvalidOrder,
  fetchGetUserOrders,
} from "./ProfileDataFetch";
import { getAuthToken } from "../../config/config";
import { CommendCard } from "../../cards/CommentCard";
import { Box, Modal } from "@mui/material";
import { CircleOutlined } from "@mui/icons-material";

export const ProfileHistory = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const jwt = getAuthToken();
  const [orders, setOrders] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchGetUserOrders(jwt)
      .then(setOrders)
      .finally(() => setIsLoading(false));
  }, [jwt]);

  if (isLoading) {
    return <div>Loading ... </div>; // 創建一個載入中組件
  }

  const [valid, pending, disannul, finish, cancel] = orders;

  return (
    <>
      <div>
        <h1 className="pb-3 text-xl font-bold">有效訂單：</h1>
        <div className="flex flex-wrap gap-4 min-w-96">
          {(Array.isArray(valid) && valid.length > 0) ||
          (Array.isArray(disannul) && disannul.length > 0) ? (
            <>
              {valid.length > 0 &&
                valid.map((order, idx) => (
                  <HistoryRoomCard
                    isClient={true}
                    wantCancel={true}
                    key={idx}
                    order={order}
                  />
                ))}
              {disannul.length > 0 &&
                disannul.map((order, idx) => (
                  <HistoryRoomCard
                    key={idx}
                    waitAnswer={true}
                    order={order}
                    isClient={true}
                  />
                ))}
            </>
          ) : (
            <div className="text-xl font-semibold">目前無未有效訂單</div>
          )}
        </div>
      </div>
      <div className="mt-5">
        <h1 className="pb-3 text-xl font-bold">待審核訂單：</h1>
        <div className="flex flex-wrap gap-4 min-w-96">
          {Array.isArray(pending) && pending.length !== 0 ? (
            pending.map((order, idx) => (
              <HistoryRoomCard
                canCancel={true}
                key={idx}
                order={order}
                isClient={true}
              />
            ))
          ) : (
            <div className="text-xl font-semibold">目前無待審核訂單</div>
          )}
        </div>
      </div>
      <div className="mt-5">
        <h1 className="pb-3 text-xl font-bold">已完成訂單：</h1>
        <div className="flex flex-wrap gap-4 min-w-96">
          {Array.isArray(finish) && finish.length !== 0 ? (
            finish.map((order, idx) => (
              <HistoryRoomCard
                finished={true}
                setOpen={setOpen}
                key={idx}
                setSelectedOrder={setSelectedOrder}
                order={order}
                isClient={true}
              />
            ))
          ) : (
            <div className="text-xl font-semibold">目前無歷史訂單</div>
          )}
        </div>
      </div>
      <div className="mt-5">
        <h1 className="pb-3 text-xl font-bold">無效訂單：</h1>
        <div className="flex flex-wrap gap-4 min-w-96">
          {Array.isArray(cancel) && cancel.length !== 0 ? (
            cancel.map((order, idx) => (
              <HistoryRoomCard
                canceled={true}
                key={idx}
                order={order}
                isClient={true}
              />
            ))
          ) : (
            <div className="text-xl font-semibold">目前無取消訂單</div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableScrollLock
        disablePortal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          role="dialog"
          aria-modal="true"
          className="w-[400px] rounded-3xl bg-white p-4 outline-none"
        >
          <CommendCard
            order={selectedOrder}
            isEditing={true}
            setOpenModal={setOpen}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
};
