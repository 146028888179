import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import engineer from "../assets/engineer.png";
import leader from "../assets/leader.png";
import designer from "../assets/designer.png";
import manager from "../assets/manager.png";
import student from "../assets/student.png";
import { useNavigate } from "react-router-dom";

const userTestimonials = [
  {
    avatar: <Avatar alt="陳" src={designer} />,
    name: "陳小姐",
    occupation: "自由接案設計師",
    testimonial:
      "「使用SevenTao後，我的空房不再閒置，每個月都有穩定的額外收入。上架流程簡單明瞭，還能靈活安排房子短租或自用，真的很方便！」",
  },

  {
    avatar: <Avatar alt="吳" src={manager} />,
    name: "吳小姐",
    occupation: "軟體工程師",
    testimonial:
      "「在這裡找到的房間非常乾淨，屋主也很親切，入住流程完全無壓力。我覺得這是一個比傳統住宿更個性化的選擇！」",
  },
  {
    avatar: <Avatar alt="張" src={engineer} />,
    name: "張先生",
    occupation: "行銷專員",
    testimonial:
      "「因為使用了SevenTao，讓我出差時能輕鬆找到實惠的臨時住處，而且可以選擇很多有設計感的房子，完全符合我的需求！」",
  },
  {
    avatar: <Avatar alt="林" src={leader} />,
    name: "林先生",
    occupation: "某小型企業老闆",
    testimonial:
      "「SevenTao讓我快速找到適合的房客，不但能最大化空間的利用，還能在需要的時候短暫收回房子，解決了我的使用彈性需求。」",
  },
  {
    avatar: <Avatar alt="李" src={student} />,
    name: "李小姐",
    occupation: "大學生",
    testimonial:
      "「和朋友一起旅遊時用這平台找到一間超棒的房子，ＣＰ值高，房東人也很好，讓我們的旅行更輕鬆愉快！」",
  },
];

export default function Testimonials({ setReadAbout }) {
  const navigate = useNavigate();

  const handleClick = () => {
    const read = localStorage.getItem("read");
    if (!read) {
      localStorage.setItem("read", "yes");
      setReadAbout("yes");
      navigate("/");
    } else {
      navigate("/");
    }
  };
  return (
    <Container
      id="testimonials"
      maxWidth="lg"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          variant="h3"
          color="text.primary"
          sx={{
            mb: { xs: 1, md: 4 },
            fontSize: { xs: 32, md: 36 },
            fontWeight: 500,
          }}
        >
          眾多用戶，真情推薦！
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} key={index} sx={{ display: "flex" }}>
            <Card
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
                borderRadius: 4,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: "#343a40",
          ":hover": {
            backgroundColor: "#343a40aa",
          },
          height: 50,
          width: 110,
          fontSize: 18,
        }}
      >
        立即體驗
      </Button>
    </Container>
  );
}
