import { useEffect, useState } from "react";
import { AppBar, Box, Button, Toolbar } from "@mui/material/";
import whitelogo from "../assets/whitelogo.png";
import blacklogo from "../assets/blacklogo.png";
import { useNavigate } from "react-router-dom";

export default function NavBar({ setReadAbout }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const handleClick = () => {
    const read = localStorage.getItem("read");
    if (!read) {
      localStorage.setItem("read", "yes");
      setReadAbout("yes");
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: scrollPosition > 0 ? "#343a40" : "#edede9",
        maxWidth: "1500px",
        minWidth: "500px",
        width: "100%",
        height: 80,
        boxShadow: "none",
        transition: "background-color 0.3s ease",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          maxWidth: "1500px",
          minWidth: "500px",
          width: "100%",
          mx: "auto",
        }}
      >
        <Box
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={scrollPosition > 0 ? blacklogo : whitelogo}
            sx={{
              maxWidth: 100,
              height: "auto",
              ml: 2,
            }}
          />
        </Box>
        <Button
          onClick={handleClick}
          variant="text"
          sx={{
            height: 50,
            width: 110,
            fontSize: 18,
            color: "white",
            bgcolor: "#343a40",
            fontWeight: 500,
            ":hover": {
              bgcolor: "#343a40aa",
            },
            mr: 2,
          }}
        >
          立即體驗
        </Button>
      </Toolbar>
    </AppBar>
  );
}
