import { Button, Card, CardContent } from "@mui/material";
import * as React from "react";

export const RoomCards = ({ room, handleOpen }) => {
  const defaultPic =
    "https://www.thespruce.com/thmb/2_Q52GK3rayV1wnqm6vyBvgI3Ew=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/put-together-a-perfect-guest-room-1976987-hero-223e3e8f697e4b13b62ad4fe898d492d.jpg";

  return (
    <Card
      sx={{
        width: 230,
        bgcolor: "#e9ecef88",
        p: "5px",
        borderRadius: "5%",
      }}
    >
      <div className="mt-1 px-1">
        <p className="px-2 m-0 text-lg font-bold">{room?.roomName}</p>
        {room?.start && (
          <p className="px-2 m-0 text-sm text-slate-600 font-medium">
            {room?.start} 到 {room?.end}
          </p>
        )}
      </div>

      <img
        className="w-[250px] h-[250px] object-cover rounded-xl mt-1"
        src={
          Array.isArray(room?.roomPic) && room.roomPic.length > 0
            ? room.roomPic[0]
            : defaultPic
        }
        alt=""
      />

      <CardContent className="mt-2 ml-2 p-0 flex" orientation="horizontal">
        {room?.price > 0 && (
          <div className="flex-col">
            <p className="m-0 text-xs text-slate-600 font-medium">
              住宿預估金額
            </p>
            <p className="m-0 text-xl text-slate-600 font-medium">
              NT$ {room?.price}
            </p>
          </div>
        )}
        <Button
          variant="outlined"
          size="small"
          fullWidth={!room?.price || room?.price == 0}
          className="my-2"
          onClick={handleOpen}
          sx={{
            ml: "auto",
            mt: "auto",
            alignSelf: "center",
            fontWeight: 600,
            borderColor: "#1a759f66",
            "&:hover": {
              borderColor: "#1a759f",
            },
            color: "black",
          }}
        >
          查看更多
        </Button>
      </CardContent>
    </Card>
  );
};
