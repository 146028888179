import React, { useEffect, useState } from "react";
import { HotelCard } from "../../cards/HotelCard";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchFindBossHotels } from "../HotelPageFetch";
import { getAuthToken } from "../../config/config";
import { fetchGetUserData } from "../../member/profile/ProfileDataFetch";

export const BossHotelList = ({ userData }) => {
  const navigate = useNavigate();
  const jwt = getAuthToken();
  const [hotels, setHotels] = useState();
  useEffect(() => {
    fetchFindBossHotels(jwt).then((hotels) => {
      setHotels(hotels);
    });
  }, [jwt]);
  if (!hotels) {
    return (
      <div className="flex items-center justify-center h-screen bg-black">
        <img
          className="max-w-full max-h-full object-contain"
          src="https://i.redd.it/ubbi1p7z7euc1.gif"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <div className="mt-28 m-10">
      <div className="flex flex-wrap gap-6">
        <Button
          onClick={() => navigate("/boss/hotel/create")}
          sx={{ color: "black", width: "300px", fontSize: "25px" }}
        >
          新 增<br /> 旅 店
        </Button>

        {hotels?.map((hotel) => (
          <HotelCard
            bossPage={true}
            key={hotel?.hotelId}
            hotel={hotel}
            isBoss={true}
          />
        ))}
      </div>
    </div>
  );
};
