import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";

import React, { useState } from "react";
import {
  AspectRatio,
  Business,
  FreeBreakfast,
  Landscape,
  People,
  Water,
  Wifi,
} from "@mui/icons-material";
import { Button, Chip } from "@mui/material";
import { fetchCreateOrder } from "../visitor/HotelPageFetch";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../config/config";
import { Payment } from "./Payment";

export const RoomDataCard = ({
  room,
  handleClose,
  setOpenModal,
  setOpenSignIn,
}) => {
  const jwt = getAuthToken();
  const navigate = useNavigate();
  const [displayPay, setDisplayPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const supplyInRoom = [
    {
      name: "免費迷你吧",
      icon: <FreeBreakfast fontSize="small" />,
    },
    { name: "免費WIFI", icon: <Wifi fontSize="small" /> },
    { name: "城市景觀", icon: <Business fontSize="small" /> },
    { name: "無敵海景", icon: <Water fontSize="small" /> },
    { name: "舒適山景", icon: <Landscape fontSize="small" /> },
  ];

  const photo = [
    "https://www.thespruce.com/thmb/2_Q52GK3rayV1wnqm6vyBvgI3Ew=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/put-together-a-perfect-guest-room-1976987-hero-223e3e8f697e4b13b62ad4fe898d492d.jpg",
  ];
  const carouselPhoto =
    Array.isArray(room?.roomPic) && room?.roomPic.length > 0
      ? room?.roomPic
      : photo;
  const handleSubmitOrder = (prime) => {
    setLoading(true);
    if (!jwt) {
      setOpenModal(false);
      setOpenSignIn(true);
    } else {
      const orderData = {
        roomId: room?.roomId,
        checkInDate: room?.start,
        checkOutDate: room?.end,
        totalPrice: room?.price,
      };
      console.log(orderData);
      fetchCreateOrder(jwt, prime, orderData)
        .then((data) => {
          if (data) {
            handleClose();
            alert("訂房成功！");
            navigate("/member/history");
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <div className="pb-3 rounded-3xl overflow-hidden">
      <CCarousel className="rounded-t-2xl" controls indicators interval={10000}>
        {carouselPhoto?.map((image, index) => (
          <CCarouselItem className="w-full sm:h-[350px] h-[300px]" key={index}>
            <CImage
              className="d-block w-full h-full object-cover"
              src={image}
              alt="slide"
            />
          </CCarouselItem>
        ))}
      </CCarousel>
      <div className="p-3">
        <h1 className="text-xl">{room?.roomName}</h1>
        <div className="text-gray-600 flex items-center space-x-6">
          <div className="flex items-center space-x-1">
            <AspectRatio fontSize="medium" />
            <p className="text-[12px] pl-1 m-0">
              室內大小：{room?.roomSize} 坪
            </p>
          </div>

          <div className="flex items-center space-x-1">
            <People fontSize="medium" />
            <p className="text-[12px] pl-1 m-0">可容納 {room?.capacity} 人</p>
          </div>
        </div>

        <div className="mt-2 flex flex-wrap items-center">
          {room?.specialties?.map((name, index) => {
            const supplyItem = supplyInRoom.find((item) => item.name === name);
            if (supplyItem) {
              return (
                <div key={index} className="mr-1 flex ">
                  <div className="pr-3 flex">{supplyItem.icon}</div>
                  <p className="w-[86px] m-0 text-[15px]">{name}</p>
                </div>
              );
            }
          })}
        </div>
        <div className="mt-2 flex flex-wrap items-center">
          {room?.specialties?.map((name, index) => {
            const supplyItem = supplyInRoom.find((item) => item.name === name);
            if (!supplyItem) {
              return (
                <div key={index} className="mr-1 flex ">
                  <Chip
                    variant="outlined"
                    className="bg-transparent  px-1 m-1 text-[15px]"
                    label={name}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>

      {room?.price > 0 && (
        <div className="mx-3 mb-3 flex justify-between items-center">
          <div>
            <p className="m-0">
              住宿期間：{room.start} - {room.end}
            </p>
            <p className="text-xl font-bold m-0 align-bottom">
              預計金額為 : {room.price} 元
            </p>
          </div>
          {!displayPay && (
            <Button
              onClick={() => setDisplayPay(true)}
              href="#payment"
              sx={{
                "&:hover": {
                  bgcolor: "#023e8a",
                },
                backgroundColor: "#0077b6",
                color: "white",
                height: "60px",
              }}
            >
              立刻下訂 GO
            </Button>
          )}
        </div>
      )}
      {displayPay && (
        <div id="payment">
          <Payment handleSubmitOrder={handleSubmitOrder} loading={loading} />
        </div>
      )}
      {room?.price <= 0 && (
        <div className="text-center font-medium">
          想立即預約入住嗎？
          <span
            className="hover:underline hover:font-extrabold cursor-pointer"
            onClick={() => navigate("/")}
          >
            點我前往首頁搜尋入住日期！
          </span>
        </div>
      )}
    </div>
  );
};
