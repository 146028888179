import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";

import React, { useState } from "react";
import {
  AspectRatio,
  Business,
  FreeBreakfast,
  Landscape,
  People,
  Water,
  Wifi,
} from "@mui/icons-material";
import { PriceChart } from "../cards/PriceChart";
import { Button, Chip } from "@mui/material";
import { getAuthToken } from "../config/config";

export const RoomDataCard = ({
  isEditing,
  room,
  handleDelete,
  handleClose,
  index
}) => {
  const jwt = getAuthToken();
  const supplyInRoom = [
    {
      name: "免費迷你吧",
      icon: <FreeBreakfast fontSize="small" />,
    },
    { name: "免費WIFI", icon: <Wifi fontSize="small" /> },
    { name: "城市景觀", icon: <Business fontSize="small" /> },
    { name: "無敵海景", icon: <Water fontSize="small" /> },
    { name: "舒適山景", icon: <Landscape fontSize="small" /> },
  ];
  const defaultPic =
    "https://www.thespruce.com/thmb/2_Q52GK3rayV1wnqm6vyBvgI3Ew=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/put-together-a-perfect-guest-room-1976987-hero-223e3e8f697e4b13b62ad4fe898d492d.jpg";
  const photo = [
    defaultPic,
    defaultPic,
    defaultPic,
    defaultPic,
    defaultPic,
    defaultPic,
    defaultPic,
  ];
  const carouselPhoto = room?.roomPic.length !== 0 ? room?.roomPic : photo;
  
  const handleDeleteRoom = () => {
    if (window.confirm("確定要刪除？")) {
      handleDelete(index);
    }
    handleClose();
  };

  return (
    <div className="pb-3 rounded-3xl overflow-hidden">
      <CCarousel className="rounded-t-2xl" controls indicators interval={10000}>
        {carouselPhoto?.map((image, index) => (
          <CCarouselItem className="w-full h-[350px]" key={index}>
            <CImage
              className="d-block w-full h-full object-cover"
              src={image}
              alt="slide"
            />
          </CCarouselItem>
        ))}
      </CCarousel>
      <div className="p-3">
        <h1 className="text-xl">{room?.roomName}</h1>
        <div className="text-gray-600 flex items-center space-x-6">
          <div className="flex items-center space-x-1">
            <AspectRatio fontSize="medium" />
            <p className="text-[12px] pl-1 m-0">
              室內大小：{room?.roomSize} 坪
            </p>
          </div>

          <div className="flex items-center space-x-1">
            <People fontSize="medium" />
            <p className="text-[12px] pl-1 m-0">可容納 {room?.capacity} 人</p>
          </div>
        </div>

        <div className="mt-2 flex flex-wrap items-center">
          {room?.specialties.map((name, index) => {
            const supplyItem = supplyInRoom.find((item) => item.name === name);
            if (supplyItem) {
              return (
                <div key={index} className="mr-1 flex ">
                  <div className="pr-3 flex">{supplyItem.icon}</div>
                  <p className="w-[86px] m-0 text-[15px]">{name}</p>
                </div>
              );
            }
          })}
        </div>
        <div className="mt-2 flex flex-wrap items-center">
          {room?.specialties.map((name, index) => {
            const supplyItem = supplyInRoom.find((item) => item.name === name);
            if (!supplyItem) {
              return (
                <div key={index} className="mr-1 flex ">
                  <Chip variant="outlined" className="bg-transparent  px-1 m-1 text-[15px]" label={name}/>
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className="font-bold mt-1 mx-3 h-[190px] pb-3">
        <p>未來一週房間價格</p>
        <PriceChart isBoss={false} priceList={room?.prices} />
      </div>

      {isEditing && (
        <div className="mt-3 w-[95%] mx-auto">
          <Button
            onClick={handleDeleteRoom}
            fullWidth
            color="error"
            variant="contained"
          >
            刪除房間
          </Button>
        </div>
      )}
    </div>
  );
};
