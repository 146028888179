import { Box, Container, Grid, Typography, Button } from "@mui/material";
import balance from "../assets/balance.png";
import cancel from "../assets/cancel.png";
import opinion from "../assets/opinion.png";
import { useNavigate } from "react-router-dom";

export default function FeaturesCus({ setReadAbout }) {
  const navigate = useNavigate();

  const handleClick = () => {
    const read = localStorage.getItem("read");
    if (!read) {
      localStorage.setItem("read", "yes");
      setReadAbout("yes");
      navigate("/");
    } else {
      navigate("/");
    }
  };
  return (
    <Container
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: "100%",

          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          variant="h3"
          color="text.primary"
          sx={{
            mb: 4,
            fontSize: { xs: 25, md: 36 },
            lineHeight: 1.5,
            fontWeight: 450,
            mx: 2,
          }}
        >
          使用 <b>SevenTao</b> 訂房，
          <br />
          訂到的不僅僅是一間房間，更是一段獨特的故事！
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              pointerEvents: "none",
            }}
          >
            <Box
              component="img"
              src={balance}
              sx={{ height: 300, px: 3, pt: 3, pb: "30px", mb: "4px" }}
            />
            <Typography variant="h6" color="black" sx={{ mb: 2 }}>
              即時比價
            </Typography>
            <Typography variant="body2" color="black" sx={{ mx: 2, mb: 2 }}>
              透過精準的篩選功能，根據地點、日期及人數等條件，即時呈現可預訂的房間與最低房價，讓您一目了然，輕鬆找到最符合需求的住宿選擇。
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              src={cancel}
              sx={{
                height: "300px",
                px: 4,
                pt: "35px",
                pb: "35px",
                pointerEvents: "none",
              }}
            />
            <Typography variant="h6" color="black" sx={{ mb: "20px" }}>
              彈性訂房
            </Typography>
            <Typography variant="body2" color="black" sx={{ mx: 2, mb: 2 }}>
              提供彈性的取消預訂功能，讓旅客根據行程變化調整住宿計畫，提升旅遊的自由度與靈活性。
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              src={opinion}
              sx={{
                height: 280,
                py: 3,
                pt: "15px",
                pb: "20px",
                mb: "20px",
                pointerEvents: "none",
              }}
            />
            <Typography variant="h6" color="black" sx={{ mb: "20px" }}>
              真實評論
            </Typography>
            <Typography variant="body2" color="black" sx={{ mx: 2, mb: 2 }}>
              用戶的住宿評論將影響旅店評分系統，幫助未來旅客選擇更優質的住宿環境，讓每一次旅程都能更安心、更有保障。
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          backgroundColor: "#343a40",
          ":hover": {
            backgroundColor: "#343a40aa",
          },
          height: 50,
          width: 110,
          fontSize: 18,

          whiteSpace: "nowrap",
        }}
      >
        立即體驗
      </Button>
    </Container>
  );
}
