import { Navigate, redirect, Route, Routes } from "react-router-dom";
import { VisitorRouter } from "./router/VisitorRouter";
import { BossRouter } from "./router/BossRouter";
import { MemberRouter } from "./router/MemberRouter";
import LandingPage from "./landingPage/LandingPage";
import { useEffect, useState } from "react";

function App() {
  const [readAbout, setReadAbout] = useState(localStorage.getItem("read"));
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/admin/*" element={<AdminRouter />}></Route> */}
        <Route path="/boss/*" element={<BossRouter />}></Route>
        <Route path="/member/*" element={<MemberRouter />}></Route>
        <Route path="/landingPage" element={<LandingPage setReadAbout={ setReadAbout} />}></Route>
        <Route
          path="/*"
          element={
            readAbout ? (
              <VisitorRouter />
            ) : (
              <Navigate to="/landingPage" replace />
            )
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
