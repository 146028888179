import {
  AppBar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SigninForm } from "../member/SigninForm";
import { SignupForm } from "../member/SignupForm";
import {
  fetchBeHoteler,
  fetchGetUserData,
} from "../member/profile/ProfileDataFetch";
import { fetchLogOut } from "../member/UserSignInUpFetch";
import { getAuthToken } from "../config/config";
import Cookies from "universal-cookie";
import blacklogo from "../assets/blacklogo.png";

export const Navigator = ({ setOpen, open }) => {
  const [wait, setWait] = useState(false);

  const jwt = getAuthToken();
  const cookie = new Cookies();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  useEffect(() => {
    if (jwt)
      fetchGetUserData(jwt).then((data) => {
        setUserData(data);
      });
  }, [jwt]);
  const ishoteler = userData?.role !== "ROLE_CUSTOMER";
  const beBoss = () => {
    userData
      ? window.confirm("你確定要開旅店嗎？") &&
        fetchBeHoteler(jwt)
          .then(() => {
            alert("修改身份成功");
            navigate("/boss/hotels");
          })
          .catch((e) => {
            console.log(e);
          })
      : handleOpen();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openData = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDataClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = () => {
    setOpen(true);
    handleDataClose();
  };
  const handleClose = () => setOpen(false);

  const [isSigninForm, setIsSigninForm] = useState(true);

  const handleChangeSignForm = () => {
    setIsSigninForm((isSigninForm) => !isSigninForm);
  };
  const handleLogOut = () => {
    fetchLogOut(navigate);
  };
  const handleNavigateToHomeDeleteSession = () => {
    navigate("/");
    sessionStorage.clear();
    cookie.remove("searchParam");
    window.location.reload();
  };
  const bossColor = { bg: "black", hover: "#343a40" };
  const clientColor = { bg: "#344e41", hover: "#588157" };
  const navList = [
    { link: () => navigate("/member/profile"), name: "我的檔案", show: jwt },
    {
      link: beBoss,
      name: "成為店主",
      show: jwt && !ishoteler,
    },
    {
      link: () => navigate("/boss/hotels"),
      name: "我的旅店",
      show: jwt && ishoteler,
    },
    {
      link: () => navigate("/landingPage"),
      name: "SevenTao",
      show: (jwt && !ishoteler) || !jwt,
    },
    { link: handleOpen, name: "登入/註冊", show: !jwt },
    {
      link: () => handleLogOut(),
      name: "登出",
      show: jwt,
    },
  ];
  if (wait) {
    return (
      <div className="flex items-center justify-center h-screen bg-black">
        <img
          className="max-w-full max-h-full object-contain"
          src="https://i.redd.it/ubbi1p7z7euc1.gif"
          alt="Loading..."
        />
      </div>
    );
  }
  return (
    <div>
      <Box>
        <AppBar
          sx={{ bgcolor: ishoteler ? bossColor.bg : clientColor.bg }}
          component="nav"
        >
          <Toolbar
            className="w-full lg:w-[100vw] max-w-[1000px] lg:mx-auto"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="cursor-pointer block w-28 my-1"
                onClick={handleNavigateToHomeDeleteSession}
              >
                <img src={blacklogo} alt="" />
              </div>
            </div>

            <div className="flex items-center ">
              <p className="text-xl m-0">
                {!userData
                  ? "來賓您好"
                  : (userData?.nickName ||
                      userData?.firstName ||
                      userData?.lastName +
                        (userData?.sex === "male"
                          ? "先生"
                          : userData?.sex === "female"
                          ? "小姐"
                          : "")) + " 您好"}
              </p>
              <Tooltip
                disableInteractive
                TransitionComponent={Zoom}
                title="查看更多資訊"
              >
                <Avatar
                  onClick={handleClick}
                  sx={{
                    cursor: "pointer",
                    bgcolor: "rgb(75,85,99)",
                    marginX: "5px",
                  }}
                  src={userData?.photo}
                />
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={openData}
                onClose={handleDataClose}
                sx={{
                  "& .MuiPaper-root": {
                    display: "flex",
                    justifyContent: "center",
                    py: "10px",
                    fontSize: "45px",
                    bgcolor: ishoteler ? bossColor.bg : clientColor.bg,
                    color: "white",
                  },
                  "& .MuiMenuItem-root": {
                    paddingX: "50px",
                    fontSize: "1.2rem",
                    lineHeight: "35px",
                    ":hover": {
                      bgcolor: ishoteler ? bossColor.hover : clientColor.hover,
                    },
                  },
                  "& .MuiButtonBase-root": {
                    width: "200px",
                  },
                }}
              >
                {navList.map(
                  ({ link, name, show }, index) =>
                    show && (
                      <MenuItem key={index} onClick={link}>
                        {name}
                      </MenuItem>
                    )
                )}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      <Modal disableScrollLock keepMounted open={open} onClose={handleClose}>
        <div className="w-[400px] h-auto px-6 py-8 rounded-3xl translate-x-[-50%] translate-y-[-50%] absolute top-[50%] left-[50%] bg-[#ffffff] ">
          {isSigninForm ? (
            <SigninForm
              setWait={setWait}
              handleClose={handleClose}
              handleChangeSignForm={handleChangeSignForm}
            />
          ) : (
            <SignupForm
              setWait={setWait}
              handleClose={handleClose}
              handleChangeSignForm={handleChangeSignForm}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
