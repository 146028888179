import { api, api_comment } from "../config/config";

export const fetchAddComment = async (jwt, data) => {
  try {
    const comment = await api_comment.post(``, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (comment?.data.status == 200) {
      console.log(comment.data.message);
      alert("評論新增成功");
      window.location.reload();
      return comment.data.data;
    }
    console.log(comment.data.message);
  } catch (error) {
    console.error("Error adding comment:", error);
    alert("評論新增失敗，請稍後再試");
  }
};
export const fetchDeleteComment = async (jwt, commentId) => {
  try {
    const status = await api_comment.delete(`/${commentId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (status?.data.status == 200) {
      console.log(status.data.message);
      window.location.reload();
      alert("評論刪除成功");
    }
    console.log(status.data.message);

  } catch (error) {
    console.error("Error deleting comment:", error);
    alert("評論刪除失敗，請稍後再試");
  }
};
