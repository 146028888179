import { api, api_hotel, api_order, api_user } from "../../config/config";

export const fetchGetUserData = async (jwt) => {
  try {
    const user = await api_user.get(`/member`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (user?.data.status == 200) {
      console.log(user.data.message);
      return user.data.data;
    }
    console.log(user.data.message);
  } catch (error) {
    console.log("Error fetching user:", error);
  }
};

export const fetchUpdateUserData = async (jwt, data) => {
  try {
    const user = await api_user.put(`/member`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (user?.data.status == 200) {
      alert(user.data.message);
      window.location.reload();
      return;
    }
    console.log(user.data.message);
    return user?.data.message;
  } catch (error) {
    console.log("Error fetching user:", error);
  }
};
export const fetchBeHoteler = async (jwt) => {
  try {
    const status = await api_user.put(
      `/member/hoteler`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    if (status?.data.status == 200) {
      console.log(status.data.message);
      return status.data.data;
    }
    console.log(status.data.message);
  } catch (error) {
    console.log("Error fetching user:", error);
  }
};

export const fetchGetFavoriteHotels = async (jwt) => {
  try {
    const hotels = await api_hotel.get(`/userFavorites`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (hotels?.data.status == 200) {
      console.log(hotels.data.message);
      return hotels.data.data;
    }
    console.log(hotels.data.message);
  } catch (error) {
    console.log("Error fetching user:", error);
  }
};
export const fetchCheckIsFavoriteHotel = async (hotelId, jwt) => {
  try {
    const isFavorite = await api_hotel.get(`/${hotelId}/userFavorites`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (isFavorite?.data.status == 200) {
      console.log(isFavorite.data.message);
      return isFavorite.data.data;
    }
    console.log(isFavorite.data.message);
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};

export const fetchGetUserOrders = async (jwt) => {
  try {
    const orders = await api_order.get(`/all`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (orders?.data.status == 200) {
      console.log(orders.data.message);
      return orders.data.data;
    }
    console.log(orders.data.message);
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};
export const fetchDeleteInvalidOrder = async (jwt, orderId) => {
  if (window.confirm("你確定要刪除？")) {
    try {
      const status = await api_order.delete(`/${orderId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (status?.data.status == 200) {
        console.log(status.data.message);
      }
      window.location.reload();
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  } else {
  }
};
export const fetchCancelOrder = async (jwt, orderId) => {
  if (window.confirm("你確定要取消？")) {
    try {
      const status = await api_order.put(
        `/cancel/${orderId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (status?.data.status == 200) {
        console.log(status.data.message);
      }
      window.location.reload();
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  } else {
  }
};
export const fetchWantCancelOrder = async (jwt, orderId) => {
  if (window.confirm("你確定要取消？")) {
    try {
      const status = await api_order.put(
        `/wantCancel/${orderId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (status?.data.status == 200) {
        console.log(status.data.message);
      }
      window.location.reload();
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  } else {
  }
};
