import React, { useEffect, useState } from "react";
import { HotelCard } from "../../cards/HotelCard";
import { useNavigate } from "react-router-dom";
import { fetchGetFavoriteHotels } from "./ProfileDataFetch";
import { getAuthToken } from "../../config/config";

export const ProfileFavorite = () => {
  const jwt = getAuthToken();
  const navigate = useNavigate();
  const [hotels, setHotels] = useState();

  useEffect(() => {
    fetchGetFavoriteHotels(jwt).then((hotels) => {
      setHotels(hotels);
    });
  }, [jwt]);

  if (!hotels) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      {hotels?.length == 0 && (
        <div className="text-xl font-semibold">目前沒有我所喜愛的旅店...</div>
      )}
      <div className="flex flex-wrap gap-5 transition">
        {hotels?.map((hotel, index) => (
          <HotelCard
            hotel={hotel}
            onClick={() => navigate(`/hotel/${index}`)}
            isClient={true}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
