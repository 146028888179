import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import {
  fetchCheckIsFavoriteHotel,
  fetchGetUserData,
} from "../member/profile/ProfileDataFetch";
import { fetchUpdateLikeList } from "../visitor/HotelPageFetch";
import { fetchDeleteHotel } from "../boss/HotelPageFetch";
import { getAuthToken } from "../config/config";

export const HotelCard = ({ isBoss, bossPage, hotel }) => {
  const jwt = getAuthToken();
  const hotelData = hotel;
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState();

  const hotelId = hotelData?.hotelId;
  const truncateText = (text, limit) => {
    if (!text) return ""; // 確保 text 存在
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (jwt && !isBoss)
      fetchCheckIsFavoriteHotel(hotelId, jwt).then((check) => {
        if (check) {
          console.log(check);
          setIsFavorite(check);
        }
      });
  }, [jwt, hotelId]);
  const handleDeleteHotel = (hotelId) => {
    if (window.confirm("你確定要刪除嗎？")) {
      fetchDeleteHotel(hotelId, jwt);
    } else {
      return;
    }
  };

  const handleFavoriteHotel = () => {
    setIsFavorite(!isFavorite);
    fetchUpdateLikeList(jwt, hotelData.hotelId);
  };
  const defaultFoto =
    "https://www.vanorohotel.com/wp-content/uploads/2021/07/drz-vanoro_6737.jpg";

  return (
    <div>
      <Card
        sx={{
          width: 300,
          height: "auto",
          paddingBottom: "10px",
          bgcolor: "#ffffffaa",
          borderRadius: "30px",
          borderColor: "#8d99ae",
          borderWidth: isBoss ? "3px" : "0",
        }}
      >
        <div
          onClick={
            isBoss
              ? () => navigate(`/boss/hotel/${hotelData?.hotelId}`)
              : () => navigate(`/hotel/${hotelData?.hotelId}`)
          }
          className="cursor-pointer"
        >
          <CardHeader
            avatar={<Avatar>{String(hotelData?.enName).charAt(0)}</Avatar>}
            title={truncateText(hotelData?.chName, 8)}
            subheader={truncateText(hotelData?.enName, 16)}
            action={
              <Rating
                name="text-feedback"
                value={hotelData?.score}
                readOnly
                precision={0.5}
                size="small"
                sx={{
                  mt: "15px",
                  "& .MuiRating-iconEmpty": {
                    color: "#00000000",
                  },
                  transform: "rotate(180deg)",
                }}
              />
            }
          />

          <CardMedia
            component="img"
            height="194"
            sx={{ height: "200px" }}
            style={{ objectFit: "cover" }}
            image={
              Array.isArray(hotelData?.pictures)
                ? hotelData?.pictures[0]
                : hotelData?.picture ||
                  "https://imagedelivery.net/a6-OYZSpZSiOriMeuFHR3w/10be9358-27f1-4b39-575f-b8a72bc88a00/public"
            }
          />

          <CardContent
            sx={{
              "&:last-child": {
                paddingBottom: "6px",
              },
              paddingTop: "6px",
            }}
          >
            <div
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
              className="pl-0 mb-1 h-[33px]"
            >
              {hotelData?.roomName != null &&
                hotelData?.roomName.map((room, index) => (
                  <Chip
                    key={index}
                    className="mr-[4px] my-[4px]"
                    size="small"
                    label={room}
                    variant="outlined"
                    sx={{ borderRadius: "10px", bgcolor: "#e5e5e5" }}
                  />
                ))}
            </div>
            {hotelData?.minPrice > 0 && (
              <div className="w-full">
                <p className="text-black my-1 text-right text-lg">
                  <span className="font-bold text-2xl">
                    每晚
                    <span className="px-1">{hotelData?.minPrice}</span>元
                  </span>
                  <span className="text-base text-black">起</span>
                </p>
              </div>
            )}
            <Typography
              variant="body2"
              style={{
                resize: "none",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 5,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                color: "text.secondary",
              }}
            >
              {hotelData?.introduction}
            </Typography>
          </CardContent>
        </div>
        <div>
          {jwt && !isBoss && (
            <CardActions sx={{ py: "0" }} disableSpacing>
              <IconButton onClick={handleFavoriteHotel}>
                {isFavorite ? <FavoriteIcon /> : <FavoriteBorderOutlined />}
              </IconButton>
            </CardActions>
          )}
        </div>
        <div className="mx-2 mt-auto">
          {bossPage && isBoss && (
            <Button
              onClick={() => handleDeleteHotel(hotelData?.hotelId)}
              variant="contained"
              color="error"
              fullWidth
              sx={{ borderRadius: "30px" }}
            >
              刪除旅店
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};
