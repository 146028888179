import axios from "axios";
import {
  api,
  api_boss_hotel,
  api_boss_order,
  api_boss_room,
  api_comment,
} from "../config/config";

export const fetchHotel = async (jwt, hotelId) => {
  try {
    const hotel = await api_boss_hotel.get(`/${hotelId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (hotel?.data.status == 200) {
      console.log(hotel.data.message);
      return hotel.data.data;
    }
    console.log(hotel.data.message);
  } catch (error) {
    console.log("Error fetching hotels:", error);
  }
};
export const fetchBossRoom = async (jwt, hotelId) => {
  try {
    const hotel = await api_boss_room.get(`/${hotelId}/rooms`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (hotel?.data.status == 200) {
      console.log(hotel.data.message);
      return hotel.data.data;
    }
    console.log(hotel.data.message);
  } catch (error) {
    console.log("Error fetching hotels:", error);
  }
};
export const fetchComment = async (hotelId) => {
  try {
    const comments = await api_comment.get(`/${hotelId}`);
    if (comments?.data.status == 200) {
      console.log(comments.data.message);
      return comments.data.data;
    }
    console.log(comments.data.message);
  } catch (error) {
    console.log("Error fetching comments:", error);
  }
};

// export const getLocation = async (address) => {
//   try {
//     const response = await axios.get(
//       `https://maps.googleapis.com/maps/api/geocode/json`,
//       {
//         params: {
//           address: address,
//           key: process.env.REACT_APP_googleMapsApiKey,
//         },
//       }
//     );
//     // 獲取經緯度
//     if (response.data.results.length > 0) {
//       const location = response.data.results[0].geometry.location;
//       return location;
//     } else {
//       console.error("No results found for the given address.");
//     }
//   } catch (error) {
//     console.error("Geocoding error: ", error);
//   }
// };

export const fetchFindBossHotels = async (jwt) => {
  try {
    const hotelsData = await api_boss_hotel.get(`/hotels`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (hotelsData?.data.status == 200) {
      console.log(hotelsData.data);
      return hotelsData.data.data;
    }
    console.log(hotelsData.data.message);
  } catch (error) {
    console.log(error);
  }
};
export const fetchUpdateHotel = async (data, hotelId, jwt) => {
  try {
    const hotelData = await api_boss_hotel.put(`/${hotelId}`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (hotelData?.data.status == 200) {
      console.log(hotelData.data.message);
      return hotelData.data.data;
    }
    console.log(hotelData.data.message);
  } catch (error) {
    console.log(error);
  }
};
export const fetchCreateHotel = async (data, jwt) => {
  try {
    const hotelData = await api_boss_hotel.post(``, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (hotelData?.data.status == 200) {
      alert(hotelData.data.message);
      return hotelData.data.data;
    }
    console.log(hotelData.data.message);
  } catch (error) {
    console.log(error);
  }
};
export const fetchDeleteHotel = async (hotelId, jwt) => {
  try {
    const status = await api_boss_hotel.delete(`/${hotelId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (status?.data.status == 200) {
      alert(status.data.message);
      window.location.reload();
    }
    console.log(status.data.message);
  } catch (error) {
    console.log(error);
  }
};

export const fetchCreateRoom = async (data, jwt, hotelId) => {
  try {
    const roomData = await api_boss_room.post(`/${hotelId}`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (roomData?.data.status == 200) {
      console.log(roomData.data.message);
      return roomData.data.data;
    }
    console.log(roomData.data.message);
  } catch (error) {
    console.log(error);
  }
};
export const fetchDeleteRoom = async (jwt, roomIds) => {
  try {
    console.log("準備刪除這個房間Id" + roomIds);

    const roomData = await api_boss_room.delete(``, {
      data: roomIds,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (roomData?.data.status == 200) {
      console.log(roomData.data.message);
    }
    console.log(roomData.data.message);
  } catch (error) {
    console.log(error);
  }
};

export const fetchGetHotelsRooms = async (jwt) => {
  try {
    const hotels = await api_boss_hotel.get(`/hotelAllRooms`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (hotels?.data.status == 200) {
      console.log(hotels.data.message);
      return hotels.data.data;
    }
    console.log(hotels.data.message);
  } catch (error) {
    console.log(error);
  }
};

export const fetchGetHotelsOrders = async (jwt) => {
  try {
    const orders = await api_boss_room.get(`/allOrders`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (orders?.data.status == 200) {
      console.log(orders.data.data);
      return orders.data.data;
    }
    console.log(orders.data.message);
  } catch (error) {
    console.log(error);
  }
};

export const fetchAcceptOrders = async (jwt, orderId) => {
  try {
    const order = await api_boss_order.put(
      `/accept/${orderId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    console.log();

    if (order?.data.status == 200) {
      console.log(order.data.message);
      return order.data.data;
    }
    console.log(order.data.message);
  } catch (error) {
    console.log(error);
  }
};
export const fetchAcceptCancelOrders = async (jwt, orderId) => {
  try {
    const order = await api_boss_order.put(
      `/cancel/${orderId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    if (order?.data.status == 200) {
      console.log(order.data.message);
      return order.data.data;
    }
    console.log(order.data.message);
  } catch (error) {
    console.log(error);
  }
};
