import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import dragDataPlugin from "chartjs-plugin-dragdata";

// 引入 chartjs-plugin-dragdata 插件
Chart.register(dragDataPlugin);

export const PriceChart = ({ isBoss, priceList, handleChangePrice }) => {
  // 定義圖表數據和配置

  const data = {
    labels: [
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
      "星期日",
    ],
    datasets: [
      {
        label: "價格(新台幣)",
        data: priceList,
        borderColor: "#457b9d",
        backgroundColor: "#90e0ef33",
        tension: 0.3,
        fill: true,
      },
    ],
  };

  const optionsBoss = {
    devicePixelRatio: window.devicePixelRatio || 1, // 支援高解析度
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      y: {
        min: 500,
        max: 15000,
      },
    },
    plugins: {
      dragData: {
        round: 0, // 控制數值精度
        onDragEnd: (e, datasetIndex, index, value) => {
          console.log(`${data.labels[index]} 的新價格是${value}`);
          handleChangePrice(index, value);
        },
      },
    },
  };
  const minPrice = priceList?.length
    ? Math.min(...priceList.map((p) => p)) - 500
    : 500;
  const maxPrice = priceList?.length
    ? Math.max(...priceList.map((p) => p)) + 500
    : 15000;

  const optionsClient = {
    devicePixelRatio: window.devicePixelRatio || 1, // 支援高解析度
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        min: minPrice,
        max: maxPrice,
      },
    },
    plugins: {
      dragData: false,
    },
  };

  const options = isBoss ? optionsBoss : optionsClient;

  return (
    <div style={{ position: "relative", height: "90%", width: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
};
