import { Button, Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchCancelOrder,
  fetchDeleteInvalidOrder,
  fetchWantCancelOrder,
} from "../member/profile/ProfileDataFetch";
import { getAuthToken } from "../config/config";
import {
  fetchAcceptCancelOrders,
  fetchAcceptOrders,
} from "../boss/HotelPageFetch";
import { fetchFindHotelId } from "../visitor/HotelPageFetch";

export const HistoryRoomCard = ({
  setSelectedOrder,
  setOpen,
  order,
  isClient,
  waitAnswer,
  wantCancel,
  finished,
  canceled,
  canCancel,
  pending,
}) => {
  const navigate = useNavigate();
  const jwt = getAuthToken();
  const handleWantCancel = (orderId) => {
    console.log(orderId);

    fetchWantCancelOrder(jwt, orderId).then((data) => {
      if (data) {
        console.log("成功送出");
        window.location.reload();
      }
    });
  };
  const handleCancel = (orderId) => {
    console.log(orderId);

    fetchCancelOrder(jwt, orderId).then((data) => {
      if (data) {
        console.log("成功送出");
        window.location.reload();
      }
    });
  };
  const handleAcceptCancel = (orderId) => {
    console.log(orderId);
    fetchAcceptCancelOrders(jwt, orderId).then((data) => {
      if (data) {
        console.log("成功送出");
        window.location.reload();
      }
    });
  };
  const handleAcceptOrder = (orderId) => {
    console.log(orderId);
    console.log(jwt);

    fetchAcceptOrders(jwt, orderId).then((data) => {
      if (data) {
        console.log(data);
        window.location.reload();
      }
    });
  };
  const handleAddComment = () => {
    setOpen(true);
    setSelectedOrder(order);
    console.log(order);
  };
  const handleNavigateToHotel = (roomId) => {
    fetchFindHotelId(roomId).then((hotelId) => {
      if (hotelId) navigate(`/hotel/${hotelId}`);
    });
  };
  const renderActionButton = (
    label,
    onClickHandler,
    color = "primary",
    disabled = false
  ) => {
    return (
      <div>
        <Button
          fullWidth
          onClick={onClickHandler}
          variant="contained"
          sx={{
            paddingX: "20px",
            height: "100%",
            paddingY: "4px",
            marginY: "5px",
            borderRadius: "15px",
            bgcolor: color,
          }}
          size="large"
          disabled={disabled}
        >
          {label}
        </Button>
      </div>
    );
  };

  return (
    <Card
      className="w-[320px] h-auto flex-row"
      sx={{
        bgcolor: "#8d99ae44",
        borderRadius: "30px",
        ...(!isClient &&
          order?.orderStatus == "DISANNUL" && {
            borderWidth: "3px",
            borderColor: "red",
          }),
      }}
    >
      <div className="w-[320px] h-[250px]">
        <img
          component="img"
          className="object-cover h-full w-full"
          src={order?.roomPic || "https://example.com/default.jpg"}
          alt="room view"
        />
      </div>

      <CardContent
        className="pb-3 w-full h-auto rounded-b-md"
        sx={{
          padding: "10px",
          bgcolor: "#ffffff55",
          ":last-child": { paddingBottom: "0px" },
        }}
      >
        <div className="flex-grow px-1">
          {order?.orderStatus == "DISANNUL" && !isClient && (
            <p className="text-red-600 font-bold text-2xl">
              使用者提出取消申請
            </p>
          )}
          <Typography
            sx={{ fontSize: "23px", fontWeight: "600", marginTop: "5px" }}
          >
            {order?.roomName || "預設房間名字"}
          </Typography>
          <div className="pt-1 text-gray-600">
            <p>
              入住時間：{order?.checkInDate} ~ {order?.checkOutDate}
            </p>
            <p>訂單金額：NT$ {order?.totalPrice}</p>
            {!finished && !canceled && (
              <>
                <p>訂房者姓名：{order?.name}</p>
                <p>訂房者聯絡方式：{order?.phoneNum}</p>
              </>
            )}
          </div>
        </div>

        <div className="my-auto h-auto justify-end text-center">
          {order.orderStatus == "PENDING" && !isClient && (
            <>
              {renderActionButton(
                "接受預訂",
                () => handleAcceptOrder(order?.id),
                "#3d5a80"
              )}
            </>
          )}

          {canCancel && (
            <div>
              <Button
                fullWidth
                onClick={() => handleCancel(order?.id)}
                sx={{
                  paddingX: "20px",
                  height: "100%",
                  paddingY: "2px",
                  marginY: "5px",
                  borderRadius: "15px",
                  color: "#f94144",
                }}
                size="large"
              >
                取消訂房
              </Button>
            </div>
          )}

          {waitAnswer && (
            <div>{renderActionButton("取消預訂中", null, "#3d5a80", true)}</div>
          )}

          {wantCancel && isClient && (
            <div>
              <Button
                fullWidth
                onClick={() => handleWantCancel(order?.id)}
                sx={{
                  paddingX: "20px",
                  height: "100%",
                  paddingY: "2px",
                  marginY: "5px",
                  borderRadius: "15px",
                  color: "#f94144",
                }}
                size="large"
              >
                取消訂房
              </Button>
            </div>
          )}
          {order.orderStatus == "DISANNUL" && !isClient && (
            <>
              {renderActionButton(
                "同意取消",
                () => handleAcceptCancel(order?.id),
                "#f94144"
              )}
            </>
          )}

          {isClient && (finished || canceled) && (
            <>
              {finished && (
                <>
                  {renderActionButton(
                    order?.commented ? "已完成評價" : "給予評價",
                    () => handleAddComment(order?.id),
                    "#3d5a80",
                    order?.commented
                  )}
                </>
              )}

              <>
                {renderActionButton(
                  "再次預訂",
                  () => handleNavigateToHotel(order?.roomId),
                  "#3d5a80"
                )}
              </>

              <div>
                <Button
                  fullWidth
                  onClick={() => fetchDeleteInvalidOrder(jwt, order?.id)}
                  sx={{
                    paddingX: "20px",
                    height: "100%",
                    paddingY: "2px",
                    marginY: "5px",
                    borderRadius: "15px",
                    color: "#f94144",
                  }}
                  size="large"
                >
                  刪除訂單
                </Button>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
