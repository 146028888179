import { AddPhotoAlternate, Close, CloudUpload } from "@mui/icons-material";
import { React, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  styled,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { uploadImageToCloudinary } from "../config/UploadToCloudinary";
import { fetchCreateRoom } from "./HotelPageFetch";
import { useParams } from "react-router-dom";
import { PriceChart } from "../cards/PriceChart";
import { getAuthToken } from "../config/config";

export const CreateRoomForm = ({ close, handleAddRoom }) => {
  const [uploadImage, setUploadImage] = useState(false);
  const [errMsg, setErrMsg] = useState({
    name: "",
    size: "",
    capacity: "",
  });
  const handleRemoveImage = (index) => {
    const updatedImages = [...formik.values.roomType.roomPic];
    updatedImages.splice(index, 1);
    formik.setFieldValue("roomType.roomPic", updatedImages);
  };

  const supplyInRoom = [
    "免費迷你吧",
    "免費WIFI",
    "城市景觀",
    "無敵海景",
    "舒適山景",
  ];

  const formik = useFormik({
    initialValues: {
      roomType: {
        roomPic: [],
        roomName: "",
        specialties: {
          免費迷你吧: false,
          免費WIFI: false,
          城市景觀: false,
          無敵海景: false,
          舒適山景: false,
        },
        otherSpecialties: ["按摩浴缸", "高檔沐浴用品"],
        roomPrice: [1000, 1000, 1000, 1000, 1000, 1000, 1000],
        roomSize: "",
        capacity: "",
      },
    },

    onSubmit: (values) => {
      setErrMsg({ name: "", size: "", capacity: "" });

      if (values.roomType.roomName.trim() == "") {
        setErrMsg((prev) => ({ ...prev, name: "名稱不可為空" }));
        return;
      }
      if (values.roomType.roomSize == "") {
        setErrMsg((prev) => ({ ...prev, size: "房間大小不可為空" }));
        return;
      }
      if (values.roomType.capacity == "") {
        setErrMsg((prev) => ({ ...prev, capacity: "容納人數不可為空" }));
        return;
      }

      const realSpecialties = Object.keys(values.roomType.specialties).filter(
        (key) => values.roomType.specialties[key] === true
      );
      const roomData = {
        roomPic: values.roomType.roomPic,
        roomName: values.roomType.roomName,
        specialties: [...realSpecialties, ...values.roomType.otherSpecialties],
        prices: values.roomType.roomPrice,
        roomSize: values.roomType.roomSize,
        capacity: values.roomType.capacity,
      };

      handleAddRoom(roomData);
      console.log(roomData);
      close();
      formik.resetForm();
      // fetchCreateRoom(roomData, jwt, hotelId)
      //   .then((room) => {
      //     handleAddRoom(room);
      //     formik.resetForm();
      //     close();
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    },
  });

  const [otherSpecialty, setOtherSpecialty] = useState("");
  const handleChangePrice = (index, price) => {
    const oldValue = [...formik.values.roomType.roomPrice];
    oldValue[index] = price;
    formik.setFieldValue("roomType.roomPrice", oldValue);
  };
  const handleAddSpecialty = () => {
    if (otherSpecialty.trim()) {
      const updatedSupplies = [
        ...formik.values.roomType.otherSpecialties,
        otherSpecialty,
      ];
      formik.setFieldValue("roomType.otherSpecialties", updatedSupplies);
      setOtherSpecialty("");
    }
  };
  const handleDeleteSupply = (index) => {
    const updatedSupplies = formik.values.roomType.otherSpecialties.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("roomType.otherSpecialties", updatedSupplies);
  };
  const handleImageChange = async (e) => {
    try {
      setUploadImage(true);
      const imageUrl = await uploadImageToCloudinary(e.target.files[0]);
      formik.setFieldValue("roomType.roomPic", [
        ...formik.values.roomType.roomPic,
        imageUrl,
      ]);
    } catch (error) {
      console.log("uploading failure");
    } finally {
      setUploadImage(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-1">
        <div className="flex gap-2 col-span-12">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="fileInput"
          />
          {formik.values.roomType.roomPic.length <= 3 && (
            <label className="relative" htmlFor="fileInput">
              <span className="w-32 h-32 cursor-pointer flex items-center justify-center p-3 border rounded-md border-gray-600">
                <AddPhotoAlternate className="text-[#00000088] " />
              </span>
              {uploadImage && (
                <div className="absolute left-0 right-0 top-0 bottom-0 w-32 h-32 flex justify-center items-center">
                  <CircularProgress />
                </div>
              )}
            </label>
          )}
          <div className="flex gap-2">
            {formik.values.roomType.roomPic.map((image, index) => (
              <div className="relative bg-slate-200">
                <img
                  className="w-32 h-32 object-cover"
                  key={index}
                  src={image}
                  alt=""
                />
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    outline: "none",
                  }}
                  onClick={() => handleRemoveImage(index)}
                >
                  <Close sx={{ fontSize: "1rem" }} />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-12 mt-1 ">
          <TextField
            fullWidth
            inputProps={{ maxLength: 10 }}
            sx={{
              mb: "5px",
              "& .MuiInputLabel-root": { color: "gray", fontSize: "20px" },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "black",
                fontSize: "15px",
              },
              "& .MuiInput-input": {
                fontSize: "30px",
              },
            }}
            name="roomType.roomName"
            label="請輸入房型名稱"
            required
            value={formik.values.roomType.roomName}
            onChange={formik.handleChange}
            variant="standard"
            error={errMsg.name}
            helperText={errMsg.name}
          />

          <div>
            <TextField
              className="w-[50%]"
              type="number"
              sx={{
                pr: "15px",
                "& .MuiInputLabel-root": { color: "gray", fontSize: "18px" },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "black",
                  fontSize: "13px",
                },
                "& .MuiInput-input": {
                  fontSize: "18px",
                },
              }}
              name="roomType.roomSize"
              required
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">坪</InputAdornment>
                  ),
                },
              }}
              label="房間大小"
              value={formik.values.roomType.roomSize}
              variant="standard"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 0 && value <= 100) {
                  formik.handleChange(e);
                } else if (value > 100) {
                  formik.setFieldValue("roomType.roomSize", 100);
                } else {
                  formik.setFieldValue("roomType.roomSize", 1);
                }
              }}
              error={errMsg.size}
              helperText={errMsg.size}
            />
            <TextField
              className="w-[50%]"
              type="number"
              sx={{
                "& .MuiInputLabel-root": { color: "gray", fontSize: "18px" },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "black",
                  fontSize: "13px",
                },
                "& .MuiInput-input": {
                  fontSize: "18px",
                },
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">人</InputAdornment>
                  ),
                },
              }}
              name="roomType.capacity"
              required
              label="房間可容納人數"
              value={formik.values.roomType.capacity}
              variant="standard"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 0 && value <= 50) {
                  formik.handleChange(e);
                } else if (value > 100) {
                  formik.setFieldValue("roomType.capacity", 50);
                } else {
                  formik.setFieldValue("roomType.capacity", 1);
                }
              }}
              error={errMsg.capacity}
              helperText={errMsg.capacity}
            />
          </div>
        </div>
        <div className="col-span-12 grid grid-cols-10 my-3">
          <div className="col-span-7 h-[300px]">
            <PriceChart
              isBoss={true}
              priceList={formik.values.roomType.roomPrice}
              handleChangePrice={(index, price) =>
                handleChangePrice(index, price)
              }
            />
          </div>
          <div className="col-span-3 ">
            <h2 className="text-xl m-0 text-center">每日房間價格</h2>

            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-input": {
                  p: 1,
                  height: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              startAdornment={
                <InputAdornment position="start">一</InputAdornment>
              }
              endAdornment={<InputAdornment position="end">元</InputAdornment>}
              type="number"
              value={formik.values.roomType.roomPrice[0]}
              name="roomType.roomPrice[0]"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 1 && value <= 15000) {
                  formik.setFieldValue(`roomType.roomPrice[0]`, value); // 更新 price 值
                } else if (value > 15000) {
                  formik.setFieldValue("roomType.roomPrice[0]", 15000);
                } else {
                  formik.setFieldValue("roomType.roomPrice[0]", 1);
                }
              }}
            />
            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-input": {
                  p: 1,
                  height: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              startAdornment={
                <InputAdornment position="start">二</InputAdornment>
              }
              endAdornment={<InputAdornment position="end">元</InputAdornment>}
              type="number"
              value={formik.values.roomType.roomPrice[1]}
              name="roomType.roomPrice[1]"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 1 && value <= 15000) {
                  formik.handleChange(e);
                } else if (value > 15000) {
                  formik.setFieldValue("roomType.roomPrice[1]", 15000);
                } else {
                  formik.setFieldValue("roomType.roomPrice[1]", 1);
                }
              }}
            />
            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-input": {
                  p: 1,
                  height: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              startAdornment={
                <InputAdornment position="start">三</InputAdornment>
              }
              endAdornment={<InputAdornment position="end">元</InputAdornment>}
              type="number"
              value={formik.values.roomType.roomPrice[2]}
              name="roomType.roomPrice[2]"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 1 && value <= 15000) {
                  formik.handleChange(e);
                } else if (value > 15000) {
                  formik.setFieldValue("roomType.roomPrice[2]", 15000);
                } else {
                  formik.setFieldValue("roomType.roomPrice[2]", 1);
                }
              }}
            />
            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-input": {
                  p: 1,
                  height: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              startAdornment={
                <InputAdornment position="start">四</InputAdornment>
              }
              endAdornment={<InputAdornment position="end">元</InputAdornment>}
              type="number"
              value={formik.values.roomType.roomPrice[3]}
              name="roomType.roomPrice[3]"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 1 && value <= 15000) {
                  formik.handleChange(e);
                } else if (value > 15000) {
                  formik.setFieldValue("roomType.roomPrice[3]", 15000);
                } else {
                  formik.setFieldValue("roomType.roomPrice[3]", 1);
                }
              }}
            />
            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-input": {
                  p: 1,
                  height: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              startAdornment={
                <InputAdornment position="start">五</InputAdornment>
              }
              endAdornment={<InputAdornment position="end">元</InputAdornment>}
              type="number"
              value={formik.values.roomType.roomPrice[4]}
              name="roomType.roomPrice[4]"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 1 && value <= 15000) {
                  formik.handleChange(e);
                } else if (value > 15000) {
                  formik.setFieldValue("roomType.roomPrice[4]", 15000);
                } else {
                  formik.setFieldValue("roomType.roomPrice[4]", 1);
                }
              }}
            />
            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-input": {
                  p: 1,
                  height: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              startAdornment={
                <InputAdornment position="start">六</InputAdornment>
              }
              endAdornment={<InputAdornment position="end">元</InputAdornment>}
              type="number"
              value={formik.values.roomType.roomPrice[5]}
              name="roomType.roomPrice[5]"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 1 && value <= 15000) {
                  formik.handleChange(e);
                } else if (value > 15000) {
                  formik.setFieldValue("roomType.roomPrice[5]", 15000);
                } else {
                  formik.setFieldValue("roomType.roomPrice[5]", 1);
                }
              }}
            />
            <OutlinedInput
              sx={{
                "& .MuiOutlinedInput-input": {
                  p: 1,
                  height: "20px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              startAdornment={
                <InputAdornment position="start">日</InputAdornment>
              }
              endAdornment={<InputAdornment position="end">元</InputAdornment>}
              type="number"
              value={formik.values.roomType.roomPrice[6]}
              name="roomType.roomPrice[6]"
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value > 1 && value <= 15000) {
                  formik.handleChange(e);
                } else if (value > 15000) {
                  formik.setFieldValue("roomType.roomPrice[6]", 15000);
                } else {
                  formik.setFieldValue("roomType.roomPrice[6]", 1);
                }
              }}
            />
          </div>
        </div>
        <div className="col-span-12 grid grid-cols-7">
          <div className="col-span-3">
            <FormGroup className="mx-2 h-[150px]">
              {supplyInRoom.map((name, index) => (
                <div key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          formik.values.roomType.specialties[name] || false
                        }
                      />
                    }
                    label={name}
                    name={`roomType.specialties.${name}`}
                    onChange={() => {
                      formik.setFieldValue(
                        `roomType.specialties.${name}`,
                        !formik.values.roomType.specialties[name]
                      );
                    }}
                  />
                </div>
              ))}
            </FormGroup>
          </div>
          <div className="col-span-4">
            <div className="mx-2 h-auto">
              <div className="h-full flex flex-wrap">
                {formik.values.roomType.otherSpecialties.map(
                  (supply, index) => (
                    <div className="p-1" key={index}>
                      <Chip
                        onDelete={() => handleDeleteSupply(index)}
                        label={supply}
                        variant="outlined"
                      />
                    </div>
                  )
                )}
                <div className="grid grid-cols-6 ">
                  {formik.values.roomType.otherSpecialties.length < 6 && (
                    <>
                      <TextField
                        inputProps={{ maxLength: 10 }}
                        className="col-span-4"
                        fullWidth
                        sx={{
                          my: "1px",
                          "& .MuiInputLabel-root": {
                            color: "gray",
                            fontSize: "15px",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "black",
                          },
                          "& .MuiInput-input": {
                            fontSize: "15px",
                          },
                        }}
                        label="描述（最多六項）"
                        value={otherSpecialty}
                        onChange={(e) => setOtherSpecialty(e.target.value)}
                        variant="standard"
                      />
                      <Button
                        className=" col-span-2"
                        onClick={handleAddSpecialty}
                      >
                        新增描述
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12">
          <Button
            onClick={formik.handleSubmit}
            type="submit"
            variant="contained"
            fullWidth
          >
            新增房間
          </Button>
        </div>
      </div>
    </form>
  );
};
