import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { fetchGetUserData, fetchUpdateUserData } from "./ProfileDataFetch";
import { AddPhotoAlternate, CloseRounded } from "@mui/icons-material";
import { uploadImageToCloudinary } from "../../config/UploadToCloudinary";
import { getAuthToken } from "../../config/config";

export const ProfileData = () => {
  const [personalData, setPersonalData] = useState();
  const [accountError, setAccountError] = useState(null);
  const jwt = getAuthToken();
  useEffect(() => {
    fetchGetUserData(jwt).then((user) => {
      setPersonalData(user);
    });
  }, [jwt]);

  const mydata = personalData;
  const formik = useFormik({
    initialValues: {
      photo: mydata?.photo,
      account: mydata?.account,
      lastName: mydata?.lastName,
      firstName: mydata?.firstName,
      email: mydata?.email,
      nickName: mydata?.nickName,
      sex: mydata?.sex,
      phoneNum: mydata?.phoneNum,
      address: mydata?.address,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setAccountError(null);
      setAccountError(null);
      const trimedValues = {
        photo: values.photo,
        account: values.account.trim(),
        lastName: values.lastName.trim(),
        firstName: values.firstName.trim(),
        email: values.email,
        nickName: values.nickName.trim(),
        sex: values.sex,
        phoneNum: values.phoneNum.trim(),
        address: values.address.trim(),
      };
      setLoading(true);
      fetchUpdateUserData(jwt, trimedValues)
        .then((message) => {
          console.log(message);
          if (message != null) return setAccountError(message);
          alert("資料更新成功");
        })
        .finally(() => setLoading(false));
    },
  });
  const [uploadImage, setUploadImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      setUploadImage(true);
      const image = await uploadImageToCloudinary(file);
      console.log(image);
      formik.setFieldValue("photo", image);
    } catch (error) {
      console.log("uploading failure");
    } finally {
      setUploadImage(false);
    }
  };
  if (!personalData) {
    return (
      <Modal keepMounted open>
        <div className="w-[200px] h-[200px] rounded-3xl translate-x-[-50%] translate-y-[-50%] absolute top-[50%] left-[50%] flex justify-center items-center overflow-hidden">
          <img
            className="object-cover w-[600px] h-[600px] scale-110"
            src={formik.values.photo || "https://i.redd.it/z4ewxlo0uk9d1.gif"}
            alt="Loading..."
          />
        </div>
      </Modal>
    );
  }
  return (
    <div className="max-w-[1024px]">
      <form
        className="grid grid-cols-12 w-full p-3 gap-4"
        onSubmit={formik.handleSubmit}
      >
        <div
          className="w-[150px] h-[150px] col-span-12 mr-auto border-2 rounded-xl border-[#344e41] border-solid"
          style={{
            backgroundImage: `url(${formik.values.photo})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="fileInput"
          />
          <label className="relative" htmlFor="fileInput">
            {!formik.values.photo && (
              <span className="cursor-pointer w-[150px] h-[150px] flex items-center justify-center">
                <AddPhotoAlternate
                  fontSize="large"
                  className="text-[#344e41] "
                />
              </span>
            )}
            {uploadImage && (
              <div className="absolute left-0 right-0 top-0 bottom-0 w-[150px] h-[150px] flex justify-center items-center">
                <CircularProgress />
              </div>
            )}
            {formik.values.photo && (
              <div
                onClick={() => formik.setFieldValue("photo", "")}
                className="absolute left-0 right-0 top-0 bottom-0 w-[150px] h-[120px] flex justify-center items-center"
              >
                <CloseRounded fontSize="large" className="text-gray-400" />
              </div>
            )}
          </label>
        </div>

        <TextField
          inputProps={{ maxLength: 10 }}
          className="md:col-span-4 col-span-12"
          name="account"
          label="帳號"
          error={accountError != null}
          helperText={accountError}
          variant="outlined"
          value={formik.values.account || ""}
          onChange={formik.handleChange}
          sx={{
            borderColor: "#344e41",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#344e41",
              },
            "& .MuiInputLabel-root": {
              color: "#344e41",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#344e41",
            },
          }}
        />
        <TextField
          disabled
          className="md:col-span-8 col-span-12"
          name="email"
          label="電子郵件"
          value={formik.values.email || ""}
          variant="outlined"
          sx={{
            borderColor: "#344e41",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#344e41",
              },
            "& .MuiInputLabel-root": {
              color: "#344e41",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#344e41",
            },
          }}
        />
        <TextField
          disabled
          inputProps={{ maxLength: 15 }}
          className="md:col-span-3 col-span-12"
          name="lastName"
          label="姓氏"
          value={formik.values.lastName || ""}
          variant="outlined"
          sx={{
            borderColor: "#344e41",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#344e41",
              },
            "& .MuiInputLabel-root": {
              color: "#344e41",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#344e41",
            },
          }}
        />
        <TextField
          className="md:col-span-4 col-span-12"
          inputProps={{ maxLength: 20 }}
          name="firstName"
          label="名字"
          value={formik.values.firstName || ""}
          onChange={formik.handleChange}
          variant="outlined"
          sx={{
            borderColor: "#344e41",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#344e41",
              },
            "& .MuiInputLabel-root": {
              color: "#344e41",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#344e41",
            },
          }}
        />

        <TextField
          className="md:col-span-5 col-span-12"
          inputProps={{ maxLength: 10 }}
          name="nickName"
          label="暱稱"
          value={formik.values.nickName || ""}
          onChange={formik.handleChange}
          variant="outlined"
          sx={{
            borderColor: "#344e41",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#344e41",
              },
            "& .MuiInputLabel-root": {
              color: "#344e41",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#344e41",
            },
          }}
        />
        <TextField
          className="md:col-span-3 col-span-12"
          inputProps={{ maxLength: 10 }}
          disabled
          name="phoneNum"
          label="行動電話"
          value={formik.values.phoneNum || ""}
          variant="outlined"
          sx={{
            borderColor: "#344e41",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#344e41",
              },
            "& .MuiInputLabel-root": {
              color: "#344e41",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#344e41",
            },
          }}
        />
        <TextField
          className="md:col-span-4 col-span-12"
          inputProps={{ maxLength: 30 }}
          name="address"
          label="聯絡地址"
          value={formik.values.address || ""}
          onChange={formik.handleChange}
          variant="outlined"
          sx={{
            borderColor: "#344e41",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#344e41",
              },
            "& .MuiInputLabel-root": {
              color: "#344e41",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#344e41",
            },
          }}
        />
        <FormControl
          component="fieldset"
          className=" md:col-span-5 col-span-12"
        >
          <div className="w-full flex justify-between">
            <FormControlLabel
              value="MALE"
              name="sex"
              checked={formik.values.sex === "MALE"}
              onChange={formik.handleChange}
              control={
                <Radio
                  sx={{
                    color: "#344e41",
                    "&.Mui-checked": {
                      color: "#344e41",
                    },
                  }}
                />
              }
              label="男生"
              sx={{ color: "#344e41" }}
            />
            <FormControlLabel
              value="FEMALE"
              name="sex"
              checked={formik.values.sex === "FEMALE"}
              onChange={formik.handleChange}
              control={
                <Radio
                  sx={{
                    color: "#344e41",
                    "&.Mui-checked": {
                      color: "#344e41",
                    },
                  }}
                />
              }
              label="女生"
            />
            <FormControlLabel
              value="OTHER"
              name="sex"
              checked={formik.values.sex === "OTHER"}
              onChange={formik.handleChange}
              control={
                <Radio
                  sx={{
                    color: "#344e41",
                    "&.Mui-checked": {
                      color: "#344e41",
                    },
                  }}
                />
              }
              label="其他"
            />
          </div>
        </FormControl>

        <Button
          sx={{
            bgcolor: "#344e41cc",
            "&:hover": {
              bgcolor: "#344e41",
            },
          }}
          type="submit"
          className="col-span-12"
          variant="contained"
        >
          {loading ? "更新中..." : "更新資料"}
        </Button>
      </form>
    </div>
  );
};
