import axios from "axios";
import {
  api,
  api_comment,
  api_hotel,
  api_order,
  api_room,
} from "../config/config";

export const fetchHotel = async (hotelId) => {
  try {
    const hotel = await api_hotel.get(`/${hotelId}`);
    if (hotel?.data.status == 200) {
      console.log(hotel.data.message);
      return hotel.data.data;
    }
    console.log(hotel.data.message);
  } catch (error) {
    console.log("Error fetching hotels:", error);
  }
};
export const fetchRooms = async (hotelId, start, end, people) => {
  try {
    const rooms = await api_room.get(
      `/getRooms/${hotelId}?people=${people}&start=${start}&end=${end}`
    );
    if (rooms?.data.status == 200) {
      console.log(rooms.data.message);
      return rooms.data.data;
    }
    console.log(rooms.data.message);
  } catch (error) {
    console.log("Error fetching hotels:", error);
  }
};
export const fetchComment = async (hotelId) => {
  try {
    const comments = await api_comment.get(`/${hotelId}`);
    if (comments?.data.status == 200) {
      console.log(comments.data.message);
      return comments.data.data;
    }
    console.log(comments.data.message);
  } catch (error) {
    console.log("Error fetching comments:", error);
  }
};

export const getLocation = async (address) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: address,
          key: process.env.REACT_APP_googleMapsApiKey,
        },
      }
    );
    // 獲取經緯度
    if (response.data.results.length > 0) {
      const location = response.data.results[0].geometry.location;
      return location;
    } else {
      console.error("No results found for the given address.");
    }
  } catch (error) {
    console.error("Geocoding error: ", error);
  }
};

export const fetchUpdateLikeList = async (jwt, hotelId) => {
  try {
    const hotel = await api_hotel.put(
      `/${hotelId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    if (hotel?.data.status == 200) {
      console.log(hotel.data.message);
      return hotel.data.data;
    }
    console.log(hotel.data.message);
  } catch (error) {
    console.log("Error fetching hotel:", error);
  }
};

export const fetchCreateOrder = async (jwt, prime, data) => {
  try {
    const order = await api_order.post(`/${prime}`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (order?.data.status == 200) {
      console.log(order.data.message);
      return order.data.data;
    }
    console.log(order.data.message);
  } catch (error) {
    console.log("Error fetching hotel:", error);
  }
};

export const fetchFindHotelId = async (roomId) => {
  try {
    const hotelId = await api_room.get(`/${roomId}/hotelId`);
    if (hotelId?.data.status == 200) {
      console.log(hotelId.data.message);
      return hotelId.data.data;
    }
    console.log(hotelId.data.message);
  } catch (error) {
    console.log("Error fetching hotel:", error);
  }
};
