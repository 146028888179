import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import { fetchSignIn } from "./UserSignInUpFetch";
import { useNavigate } from "react-router-dom";

export const SigninForm = ({ setWait, handleClose, handleChangeSignForm }) => {
  const [loginError, setLoginError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      account: "",
      password: "",
      rememberMe: "false",
    },

    onSubmit: (values) => {
      setLoading(true);
      const loginData = {
        userName: values.account,
        passWord: values.password,
      };
      console.log(loginData);
      setLoginError(null);
      fetchSignIn(setWait, navigate, loginData)
        .then((data) => {
          if (data != null) {
            if (data == " ") {
              setLoginError(null);
              handleClose();
            } else {
              setLoginError("帳號或密碼錯誤，請重新嘗試！");
            }
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="max-w-[1024px] ">
      <h1 className="text-3xl font-bold pl-5">登入</h1>
      <form
        autoComplete="none"
        className="grid grid-cols-12 w-full p-2 gap-2"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          required
          inputProps={{ maxLength: 40 }}
          sx={{
            "& .MuiInputLabel-root": { color: "gray" }, // Label color
            "& .MuiInputLabel-root.Mui-focused": { color: "bl  ack" }, // Focused label color
            "& .MuiFilledInput-root": {
              backgroundColor: "#fcfcfc", // 修改填充背景顏色
              "&:before": { borderBottom: "none" }, // Default underline border (before focus)
              "&:after": { borderBottom: "none" }, // Focu         bv        `1               bv                            ed underline border (after focus)
            },
            "& .MuiInputBase-input": { color: "black" }, // Input text color}}
          }}
          className="col-span-12"
          name="account"
          label="帳號或電子信箱"
          size="small"
          error={loginError != null}
          variant="filled"
          value={formik.values.account}
          onChange={formik.handleChange}
        />
        <div className=" bg-[#fcfcfc] flex col-span-12">
          <TextField
            fullWidth
            required
            inputProps={{ maxLength: 15 }}
            sx={{
              "& .MuiInputLabel-root": { color: "gray" }, // Label color
              "& .MuiInputLabel-root.Mui-focused": { color: "black" }, // Focused label color
              "& .MuiFilledInput-root": {
                backgroundColor: "#fcfcfc00", // 修改填充背景顏色
                "&:before": { borderBottom: "none" }, // Default underline border (before focus)
                "&:after": { borderBottom: "none" }, // Focused underline border (after focus)
              },
              "& .MuiInputBase-input": { color: "black" }, // Input text color}}
            }}
            type={showPassword ? "text" : "password"}
            name="password"
            label="密碼"
            error={loginError != null}
            size="small"
            variant="filled"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
            sx={{
              p: "0",
              m: "0",
              ":hover": {
                bgcolor: "#fcfcfc00",
              },
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
        <div className="col-span-12 flex justify-between ">
          <div className="text-red-600">{loginError}</div>
          <div className=" text-gray-500">忘記密碼？</div>
        </div>
        <Button
          sx={{
            mt: "5px",
            bgcolor: "#1e6091",
            padding: "4px",
            fontSize: "1.1rem",
          }}
          type="submit"
          className="col-span-12"
          variant="contained"
        >
          {loading ? (
            <img
              className="w-[30px] h-[30px]"
              src="https://cdn.pixabay.com/animation/2023/11/09/03/05/03-05-45-320_512.gif"
              alt="loading"
            />
          ) : (
            <p>登入</p>
          )}
        </Button>
      </form>
      <div className="col-span-12 text-center">
        <h2 className="text-sm font-normal m-0">
          還沒有帳號？
          <span
            className="cursor-pointer font-bold underline"
            onClick={handleChangeSignForm}
          >
            立即註冊
          </span>
        </h2>
      </div>
      <Divider sx={{ color: "gray" }}> or </Divider>

      <div className="mx-2">
        <Button
          sx={{ my: "8px", borderColor: "#1e6091", color: "#1e6091" }}
          fullWidth
          variant="outlined"
          disabled
        >
          <img
            className="mr-2 h-[20px]"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png"
            alt="google logo"
          />
          用 Google 登入
        </Button>
      </div>
    </div>
  );
};
